import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ca from './ca';
import es from './es';
import en from './en';
import Cookies from 'js-cookie'

Vue.use(VueI18n);

const messages = {
  en,
  es,
  ca
}

function getLanguage() {
  var lang = Cookies.get('language');
  if(lang && messages[lang]) {
    return lang;
  }
  const pageLanguage = document.documentElement.lang;
  if( isValidLanguage(pageLanguage) ) {
    return pageLanguage;
  }
  const browserLanguage = (navigator.language || navigator.browserLanguage).toLowerCase();
  if( isValidLanguage(browserLanguage) ) {
    return browserLanguage;
  }
  return 'en';
}

function isValidLanguage(language){
  const locales = Object.keys(messages);
  for (const locale of locales) {
    if (language.indexOf(locale) == 0) {
      return true;
    }
  }
  return false;
}

function setLanguage(language){
  if(isValidLanguage(language)){
    i18n.locale = language;
    Cookies.set('language', language);
  }
}

const i18n = new VueI18n({
  locale: getLanguage(),
  messages
})

export {i18n, setLanguage};
