import {Customer} from "@/models/Customer";

class Auth {

    _customer = null;

    _token = null;

    get customer() {
        if (this._customer) {
            return this._customer;
        }
        let customer = null;
        let customerData = localStorage.getItem('customer');
        if (customerData) {
            try {
                customer = new Customer();
                Object.assign(customer, JSON.parse(customerData));
            } catch (e) {
                console.warn('Invalid customer data found in storage');
            }
        }
        this._customer = customer;
        return customer;
    }

    set customer(customer) {
        this._customer = customer;
        if (!customer) {
            localStorage.removeItem('customer');
            return;
        }
        let customerData = JSON.stringify(this._customer);
        localStorage.setItem('customer', customerData);
    }

    get token() {
        if (this._token) {
            return this._token;
        }
        let token = localStorage.getItem('token');
        if (token) {
            this._token = token;
        }
        return this._token;
    }

    set token(token) {
        this._token = token;
        if (!token) {
            localStorage.removeItem('token');
            return;
        }
        localStorage.setItem('token', token);
    }

    getCustomer() {
        return this.customer;
    }

    getToken() {
        return this.token;
    }

    clear() {
        this.logout()
    }

    logout() {
        this.customer = null;
        this.token = null;
    }

    isAuthenticated() {
        return !!this.token;
    }

}

const auth = new Auth();
export default auth;
