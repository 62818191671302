<template>
  <div>
    <div class="clear-both"></div>
    <div class="wdgt-products" :class="{grid}">
      <div v-for="product in products" :key="product.id">
        <div class="wdgt-product">
          <div class="wdgt-image-wrapper">
            <image-file
                v-if="product.imageFile"
                :image-file="product.imageFile"
                :width="180"
                :height="180"
                :alt="product.name"
                class="image"
            />
          </div>
          <div class="wdgt-name-block">
            <div class="wdgt-name">
              {{ product.name }}
            </div>
            <div class="wdgt-time d-flex">
              <i class="material-icons-round">schedule</i>
              <span class="ml-1">{{ product.publicDuration }} {{ $t('words.minuts').toLowerCase() }}</span>
            </div>
            <div class="wdgt-description">{{ product.description | striphtml }} </div>
          </div>
          <div :class="hasDiscount(product.id) ? 'has-discount' : 'wdgt-price'"> {{ (product.price + (product.price * ( product.tax.rate / 100))) | currency }}</div>
          <div v-if="hasDiscount(product.id)" class="wdgt-price-discount wdgt-price">{{ ((product.price + (product.price * ( product.tax.rate / 100))) / 2) | currency }}</div>

          <div class="wdgt-buttons">
            <button type="button" @click="addProduct(product)">
              <span v-if="product.type === 'bookable'">
                {{ $t('actions.book') }}
              </span>
              <span v-else>
                {{ $t('actions.addToBasket') }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductRepository from '@/api/ProductRepository';
import {mapGetters} from 'vuex';
import ImageFile from "@/components/common/ImageFile";
import {toLocalTimestamp} from "@/utils/date";

export default {
  props: {
    filters: { type:Object, required: false},
    orders: { type:Object, required: false}
  },
  components: {ImageFile},
  data() {
    return {
      // START SAFAREGI TERMAL : FORCE 50% DISCOUNT IN ONLINE PRODUCTS
      idsProductWithDiscount: [
        '73ffe65e-2bbb-491c-8ecc-67f659c73175', //Entrada general
        'fa94d7e2-f144-4580-95dc-39444994bb1a'  //Entrada bonificada
      ],
      discountStartDate: new Date('2022-06-23 00:00:00'),
      discountEndDate: new Date('2022-07-31 23:59:59'),
      // END SAFAREGI TERMAL : FORCE 50% DISCOUNT IN ONLINE PRODUCTS
      grid: false,
      products: null,
      readMore:{}
    }
  },
  computed: {
    ...mapGetters('basket', ['orderDetails']),
    ...mapGetters('session', ['customer']),
  },
  created() {
    this.getProducts();
  },
  methods: {
    async getProducts() {
      const response = await ProductRepository.find(this.filters, this.orders);
      this.products = response.data.items;
    },
    addProduct(product) {
      this.$emit('add-product', product)
      window.dispatchEvent(new CustomEvent('bambuyo:add-product', { detail: {product} }));
    },
    hasDiscount(productId) {
      return  this.idsProductWithDiscount.includes(productId) &&
          toLocalTimestamp(new Date()) >= toLocalTimestamp(this.discountStartDate) &&
          toLocalTimestamp(new Date()) <= toLocalTimestamp(this.discountEndDate);
    },
    showMore(id) {
      this.$set(this.readMore, id, true);
    },
    showLess(id) {
      this.$set(this.readMore, id, false);
    },
  }
}
</script>

<style scoped>
.wdgt-products {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.wdgt-products > * {
  width: 100%;
  padding: 10px;
}

.wdgt-products .wdgt-product {
  border: 1px solid #e1e1e1;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: justify;
  font-size: 14px;
}
.wdgt-description-input input{
  font-size: 20px;
  padding: 10px 33px 10px 40px;
  margin: 0px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #dedede;
}
.wdgt-description-input{
  text-align: left;
}

.wdgt-products .wdgt-product > * {
  padding: 0px 10px 10px;
}

.wdgt-products .wdgt-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.wdgt-products .wdgt-name-block {
    max-width: 65%;
}

.wdgt-products .wdgt-name {
  flex-grow: 1;
  font-size: 1.1rem;
  padding: 10px 0px;
  font-weight: bold;
  color: #464958;
}
.wdgt-price-button-block,
.wdgt-description-input
{
  padding: 1rem;
}
/*
.wdgt-products .wdgt-description{
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
*/
.wdgt-products{
  line-height: 20px;
}
.wdgt-products .wdgt-price {
  color: #a81010;
  font-size: 1.2rem;
  font-weight: bold;
  white-space: nowrap;
}

.wdgt-products button {
  width: 100%;
  background-color: #c91919;
  color: white;
  font-size: 20px;
  padding: 10px 33px 10px 40px;
  margin: 0px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.wdgt-products button.secondary {
  background: #939393;
}
.wdgt-buttons {
  min-width: 15%;
}
@media (max-width: 1024px) {
  .wdgt-products .wdgt-product {
    flex-direction: column !important;
    align-items: center !important;
    text-align: center !important;
  }
}
.wdgt-products .has-discount{
  text-decoration:line-through;
  color:#333 !important;
  font-weight: normal !important;
}
.wdgt-name-coupon{
  padding-left: 1rem;
}
button.account-button,
button.basket-button {
  background-color: #c91919;
  color: white;
  font-size: 20px;
  padding: 10px 40px 10px 40px;
  margin: 0px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button.account-button{
  float: right;
}
/*@media (max-width: 768px) {
  .wdgt-products > * {
    width: 50%;
  }
}

@media (max-width: 425px) {
  .wdgt-products > * {
    width: 100%;
  }
*/
.more-link{
  color:#c91919;
  text-decoration: underline;
  cursor: pointer;
}
.account-button span,
.basket-button span,
.wdgt-price-button-block span{
  color: #fff !important;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
  .wdgt-price-button-block,
  .wdgt-products .wdgt-image-wrapper,
  .wdgt-name-block{
    width: 100% !important;
    max-width: 100% !important;
  }
}
.wdgt-time{
  color:#acacac;
  margin-bottom: 0.5rem;
}
.wdgt-time i,
.wdgt-time span{
  font-size: 13px;
}
</style>
