import request from '@/utils/request'
import { RequestParams } from '@/api/utils/requestUtils'

const publicApi = '/public/customers';
const privateApi = '/private/customers';

export default class CustomerRepository {

  static find(filter, sort, paging) {
    const requestParams = new RequestParams(filter, sort, paging)
    return request({
      url: requestParams.buildUrl(publicApi),
      method: 'get'
    })
  }

  static create(customer) {
    return request({
      url: publicApi,
      method: 'post',
      data:customer.getApiData()
    })
  }

  static recover(contactEmail) {
    return request({
      url: publicApi+'/recover',
      method: 'post',
      data: {
        contactEmail: contactEmail
      }
    })
  }

  static get() {
    return request({
      url: privateApi+'/info',
      method: 'get'
    })
  }

  static edit(customer) {
    return request({
      url: privateApi,
      method: 'put',
      data: customer.getApiData()
    })
  }

  static changePassword(password) {
    return request({
      url: privateApi+'/change-password',
      method: 'patch',
      data: {
        password: password
      }
    })
  }

}
