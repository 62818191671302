export class RequestParams {
  constructor (filters, sorting, paging = {}) {
    filters = filters || []
    sorting = sorting || []
    filters = Array.isArray(filters) ? filters : [filters]
    sorting = Array.isArray(sorting) ? sorting : [sorting]
    this.requestFilters = new RequestFilters(filters)
    this.requestSorting = new RequestSorting(sorting)
    this.requestPaging = new RequestPaging(paging)
  }

  buildUrl (path) {
    const queryStringFilterParams = this.requestFilters.getQueryStringParams()
    const queryStringSortingParams = this.requestSorting.getQueryStringParams()
    const queryStringPagingParams = this.requestPaging.getQueryStringParams()
    const params = []
    if (queryStringFilterParams.length) {
      params.push(queryStringFilterParams)
    }
    if (queryStringSortingParams.length) {
      params.push(queryStringSortingParams)
    }
    if (queryStringPagingParams.length) {
      params.push(queryStringPagingParams)
    }
    if (params.length) {
      return path + '?' + params.join('&')
    }
    return path
  }
}

class RequestPaging {
  constructor (paging) {
    this.paging = paging || {}
  }

  getQueryStringParams () {
    const params = []
    if (this.paging.page) {
      params.push(`page=${this.paging.page}`)
    }
    if (this.paging.limit) {
      params.push(`limit=${this.paging.limit}`)
    }
    return params.join('&')
  }
}

class RequestSorting {
  constructor (sorting) {
    this.sorting = sorting
  }

  getParams () {
    let sorting = []
    this.sorting.forEach((sortingGroup) => {
      sorting = sorting.concat(this.extractValues(sortingGroup))
    })
    return this.convertToV2(sorting)
  }

  convertToV2 (sortings) {
    const stcSorting = {}
    sortings.forEach(f => {
      stcSorting[f.f] = f.o
    })
    return stcSorting
  }

  getQueryStringParams () {
    const orderParams = this.getParams()
    if (!Object.keys(orderParams).length) {
      return ''
    }
    return `orders=${JSON.stringify(orderParams)}`
  }

  toQueryStringValue (param) {
    const value = {
      f: encodeURIComponent(param.f),
      o: param.o
    }
    return JSON.stringify(value)
  }

  extractValues (sortingGroup) {
    switch (typeof sortingGroup) {
      case 'object':
        return Object.keys(sortingGroup).map(key => this.getSortValue(key, sortingGroup[key]))
      default:
        return { f: sortingGroup, o: 'asc' }
    }
  }

  getSortValue (key, value) {
    return { f: key, o: value }
  }
}

class RequestFilters {
  constructor (filters) {
    this.filters = filters
  }

  getParams () {
    let filters = []
    this.filters.forEach((filterGroup) => {
      this.extractValues(filterGroup).forEach((values) => {
        filters = filters.concat(values)
      })
    })
    return this.convertToV2(filters)
  }

  convertToV2 (filters) {
    const stcFilters = {}
    filters.forEach(f => {
      if (!stcFilters[f.f]) {
        stcFilters[f.f] = {}
      }
      stcFilters[f.f][f.o] = f.v
    })
    return stcFilters
  }

  getQueryStringParams () {
    const filterParams = this.getParams()
    if (!Object.keys(filterParams).length) {
      return ''
    }
    return `filters=${JSON.stringify(filterParams)}`
  }

  toQueryStringValue (param) {
    const value = {
      f: encodeURIComponent(param.f),
      o: param.o,
      v: encodeURIComponent(param.v)
    }
    return JSON.stringify(value)
  }

  extractValues (filterGroup) {
    return Object.keys(filterGroup).map(key => this.getFilterValue(key, filterGroup[key]))
  }

  getFilterValue (key, value) {
    switch (typeof value) {
      case 'object':
        return this.extractObjectValue(key, value)
      default:
        return [{ f: key, o: 'eq', v: value }]
    }
  }

  extractObjectValue (field, obj) {
    return Object.keys(obj)
      .map((operator) => { return { f: field, o: operator, v: obj[operator] } })
  }
}
