import request from '@/utils/request'

const publicApi = '/public/bookingCalendars'

export default class BookingCalendarRepository {
  static findById(id) {
    return request({
      url: publicApi + '/' + id,
      method: 'get'
    })
  }

  static findCalendarSlots(productId, startsAt, endsAt) {
    return request({
      url: publicApi + '/slots',
      method: 'get',
      params: {
        productId: productId,
        startsAt: startsAt,
        endsAt: endsAt
      }
    })
  }
}
