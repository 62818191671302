import { BaseModel } from './BaseModel';
import { ImageFile } from './ImageFile';

export class ModelImage extends BaseModel {
  imageFile;
  imageFileId;

  mapImageFile(data){
    if(data.imageFile) {
      this.setImageFile(new ImageFile(data.imageFile));
    }
  }

  setImageFile(imageFile) {
    if(!imageFile) {
      this.imageFile = this.imageFileId = null
      return
    }
    this.imageFile = imageFile
    this.imageFileId = imageFile.id
  }
}
