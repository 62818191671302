import request from '@/utils/request'

const publicApi = '/public/taxes';

export default class TaxRepository {

  static find(query) {
    return request({
      url: publicApi,
      method: 'get',
      params: query
    })
  }
}
