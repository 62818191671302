<template>
  <div v-if="order">
    <div class="block">
      <div><b>{{ $t('words.date') }}: </b>{{ order.createdAt | toDate | dateTimeFormat }}</div>
      <div><b>{{ $t('words.status') }}: </b><span :class="'badge' + getBadgeByStatus(order.status)">{{ $t('_orderStatus.'+ order.status) }}</span></div>
      <div v-if="order.status === 'paid'"><b>{{ $t('words.paymentDate') }}: </b>{{ order.paymentDate | toDate | dateTimeFormat }}</div>
      <div v-if="order.status === 'paid'"><b>{{ $t('words.paymentMethod') }}: </b>{{ $t('_paymentMethod.'+ order.paymentMethod) }}</div>
    </div>
    <div class="clear-both"></div>
    <table v-if="order && order.orderDetails.length > 0">
      <thead>
        <tr>
          <th class="wdgt-product">{{ $t('words.product') }}</th>
          <th class="wdgt-unitPrice">{{ $t('words.unitPrice') }}</th>
          <th class="wdgt-quantity">{{ $t('words.quantity') }}</th>
          <th class="wdgt-discount">{{ $t('words.discount') }}</th>
          <th class="wdgt-total">{{ $t('words.total') }}</th>
          <th class="wdgt-attachments">{{ $t('words.attachments') }}</th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(item, index) in order.orderDetails" :key="index+'-'+item.id">
        <td class="wdgt-product"> <span v-if="item.product.supplier">{{ item.product.supplier.name }} - </span>{{ item.product.name }}</td>
        <td class="wdgt-unitPrice">{{ item.product.price | currency}}</td>
        <td class="wdgt-quantity">{{ item.quantity }}</td>
        <td class="wdgt-discount">{{ item.discountQuantity | currency}}</td>
        <td class="wdgt-total">{{ item.priceWithTaxes | currency }}</td>
        <td class="wdgt-attachments">
          <a v-for="file in item.files" :key="index+'-'+item.id+'-'+file.id" style="cursor: pointer;" href="#" title="Descarregar entrada" @click="downloadFile(file.id)">
            <i class="material-icons-round">picture_as_pdf</i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { Order } from '@/models/Order'
import {downloadFile} from "@/utils/functions";
export default {
  components:{},
  props:{
    order:{type:Object, required:true}
  },
  data(){
    let fields = [
      {key:'product.name', label:this.$t('words.product')},
      {key:'product.price', label:this.$t('words.unitPrice'), class:'text-right'},
      {key:'quantity', label:this.$t('words.quantity'), class:'text-center'},
      {key:'discountQuantity', label:this.$t('words.discount'), class:'text-right'},
      {key:'priceWithoutTaxes', label:this.$t('words.total'), class:'text-right'},
      {key:'giftVouchers', label:this.$t('words.attachments'), class:'text-right'},
    ];

    const taxesData = this.$store.getters['session/taxes']
    const orderObject = new Order(taxesData, this.order)

    return {
      fields,
      orderObject,
      loggedUser:  this.$store.getters['session/user']
    };
  },
  computed:{
    columns(){
      const {columns} = this.$store.getters['session/uiConfigTable']('orders');
      return columns;
    }
  },
  methods:{
    downloadFile,
    getBadgeByStatus(status) {
      if (status === 'awaiting_payment') {
        return ' badge-warning';
      } else if (status === 'paid') {
        return ' badge-success';
      } else {
        return ' badge-danger';
      }
    }
  }
}
</script>
<style scoped>
table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dedede;
  border-right: none;
  border-bottom: none;
  border-radius: 5px;
  color: #464958;
  margin: auto auto 15px;
  font-size: 16px;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 40px;
}

thead {
  background-color: #fafafa;
}

th,
td {
  border: 1px solid #dedede;
  border-left: none;
  border-top: none;
  padding: 5px;
  vertical-align: middle;
}
.wdgt-unitPrice,
.wdgt-quantity,
.wdgt-discount,
.wdgt-total{
  text-align: right;
}
i.material-icons-round {
  font-size: 16px;
  vertical-align: middle;
}
</style>
