import Vue from 'vue'

Vue.filter('currency', (value) => { 
  value = parseFloat(value);
  value = isNaN(value) ? 0 : value;
  value = value.toFixed(2);
  value = value.replace(/\.00$/, ''); // if it's an integer, remove cents
  return  value + ' €';
});

Vue.filter('toDate', function (value) {
  if (value instanceof Date) {
    return value
  }
  if (!value) {
    return new Date(0)
  }
  return new Date(value.replace(/\s/, 'T'))
})

Vue.filter('dateFormat', (value) => {
  if(!value){
    return '';
  }
  let year = value.getFullYear() + '';
  let month = (value.getMonth()+1) + '';
  month = (month.length === 2) ? month : '0'+month;
  let day = value.getDate() + '';
  day = (day.length === 2) ? day : '0'+day;
  return day + '/' + month + '/' + year;
});

Vue.filter('dateTimeFormat', function (date) {
  const dateFormatter = new Intl.DateTimeFormat([], { year: 'numeric', month: '2-digit', day: '2-digit' })
  const timeFormatter = new Intl.DateTimeFormat(['it'], { hour: '2-digit', minute: '2-digit' })
  if (!date) {
    return ''
  }
  return dateFormatter.format(date) + ' - ' + timeFormatter.format(date)
})
Vue.filter('striphtml', function (value) {
  var div = document.createElement("div");
  div.innerHTML = value;
  var text = div.textContent || div.innerText || "";
  return text;
});