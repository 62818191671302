export default {
  createElement(tag, opts){
    opts = opts || {};
    let el = document.createElement(tag);
    for(let key in opts) {
      el[key] = opts[key];
    }
    return el;
  }

}

export function loadScript (id, src) {
  return new Promise((resolve) => {
    if (document.getElementById(id)) {
      resolve()
      return
    }
    const node = document.createElement('script')
    node.id = id
    node.onload = resolve
    node.src = src
    document.head.appendChild(node)
  })
}