import FileRepository from '@/api/FileRepository';

class Images {

  images = {};

  async getImage(imageFileId, width, height) {

    if (!imageFileId) {
      return null
    }

    const imageCacheKey = imageFileId + '_w' + width + '_h' + height;

    if (this.images[imageCacheKey] === undefined) {
      let image = await FileRepository.resize(imageFileId, width, height);
      this.images[imageCacheKey] = 'data:image/png;base64, ' + Buffer.from(image, 'binary').toString('base64');
    }
    return this.images[imageCacheKey];
  }

}

const images = new Images();

export default images;
