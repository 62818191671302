<template>
  <div v-if="customer" id="profile-container" class="wdgt-container" ref="container">
    <ValidationObserver v-slot="{handleSubmit}">
      <form ref="form" @submit.prevent.stop="handleSubmit(update)">
        <div class="wdgt-form">
          <div class="form-field">
            <validation-provider rules="required" v-slot="{ errors }">
              <label for="wdgt-name">{{ $t('words.name') }}*</label>
              <input id="wdgt-name" v-model="customer.contactName" type="text" :placeholder="$t('words.name')" />
              <span class="error">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="form-field">
            <validation-provider rules="required" v-slot="{ errors }">
              <label for="wdgt-surname">{{ $t('words.surname') }}*</label>
              <input id="wdgt-surname" v-model="customer.contactSurname" type="text" :placeholder="$t('words.surname')" />
              <span class="error">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="form-field">
            <validation-provider rules="required|email" v-slot="{ errors }">
              <label for="wdgt-email">{{ $t('words.email') }}*</label>
              <input id="wdgt-email" v-model="customer.contactEmail" type="email" :placeholder="$t('words.email')"/>
              <span class="error">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="form-field">
            <validation-provider rules="required|phone" v-slot="{ errors }">
              <label for="wdgt-phone">{{ $t('words.phone') }}*</label>
              <input id="wdgt-phone" v-model="customer.contactPhone1" type="text" :placeholder="$t('words.phone')" />
              <span class="error">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="form-field">
            <validation-provider rules="validateCIFNIFNIE" v-slot="{ errors }">
              <label for="wdgt-vat">{{ $t('words.vat') }}</label>
              <input id="wdgt-vat" v-model="customer.vat" type="text" :placeholder="$t('words.vat')" />
              <span class="error">{{ errors[0] }}</span>
            </validation-provider>
          </div>
          <div class="form-field">
            <label for="wdgt-company">{{ $t('words.company') }} ({{ $t('words.optional') }})</label>
            <input id="wdgt-company" v-model="customer.companyName" type="text" :placeholder="$t('words.company')" />
          </div>
          <div class="form-field checkbox">
            <input id="wdgt-terms" type="checkbox" required>
            <label for="wdgt-terms">
              {{ $t('words.beforePrivacyPolicy') }} <a href="/politica-de-privacitat/" :title="$t('words.privacyPolicy')" target="_blank">{{ $t('words.privacyPolicy') }}</a>*
            </label>
          </div>
        </div>
        <div class="checkout-buttons">
          <button type="submit" class="updateButton green d-flex ml-auto" :disabled="disableSaveButton">
            {{ $t('actions.send') }}
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import CustomerRepository from "@/api/CustomerRepository";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {i18n} from "@/lang";
import {email, required} from "vee-validate/dist/rules";
import Swal from "sweetalert2";
extend('required', {
  ...required,
  message: i18n.t('forms.required')
});
extend('email', {
  ...email,
  message: i18n.t('forms.email')
});
extend('phone', {
  validate: (value) => {
    return value.match(/^[0-9]{9}$/);
  },
  message: i18n.t('forms.phone')
});

extend('validateCIFNIFNIE',{
  validate: (value) => {
    return value.match(/^[0-9A-Za-z]{9,12}$/);
  },
  message: i18n.t('forms.dninifcif')
});

export default {
  components: {ValidationObserver, ValidationProvider},
  data() {
    return {
      placeholderPassword: '*********',
      disableSaveButton: false
    }
  },
  computed: {
    ...mapGetters('session', ['customer'])
  },
  methods: {
    ...mapActions('basket', ['checkout','setCustomer']),
    ...mapActions('session', ['login','updateCustomerData']),
    goBack() {
      this.$emit('back')
    },
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    },
    onInvalid(ev) {
      ev.target.classList.add('touched')
    },
    async update(){
      /* merda per no duplicar clicks al boto pagar */
      if (this.disableSaveButton) {
        return false
      }
      this.disableSaveButton = true
      setTimeout(() => {
        this.disableSaveButton = false
      }, 3000);
      /* final de merda  */
      try {
        let response = await CustomerRepository.edit(this.customer);
        this.updateCustomerData(response.data);
        this.$emit("updated");
      } catch (err) {
        console.log(err);
        await Swal.fire({
          title: i18n.t('messages.sentEmailRecoveryFail'),
          text: i18n.t('messages.sentEmailRecoveryFailText'),
          icon: 'error',
          showCancelButton: false
        });
      }
    }
  }
}
</script>
<style scoped>
button[type="button"],
button[type="submit"],
input[type=checkbox] {
  cursor: pointer;
}

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dedede;
  border-right: none;
  border-bottom: none;
  border-radius: 5px;
  color: #464958;
  margin: auto auto 15px;
}

thead {
  background-color: #fafafa;
}

th,
td {
  border: 1px solid #dedede;
  border-left: none;
  border-top: none;
  padding: 5px;
  vertical-align: middle;
}

.wdgt-product-image {
  width: 70px;
  text-align: center;
}

.wdgt-product {
  width: 60%;
  text-align: left;
}

.wdgt-quantity {
  text-align: center;
}

.wdgt-price {
  text-align: right;
  white-space: nowrap;
}

#_pg-app input.wdgt-quantity {
  -moz-appearance: textfield;
  appearance: textfield;
  margin-bottom: 0;
  display: inline;
  width: 50px;
}

input.wdgt-quantity::-webkit-inner-spin-button,
input.wdgt-quantity::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input.touched:valid {
  /*
  border: inherit !important;
  outline: inherit !important;
  */
}

input.touched:invalid {
  border: 1px solid rgb(156, 33, 25) !important;
  outline: 1px solid rgb(156, 33, 25) !important;
  box-shadow: 0 0 0 0 rgba(156, 33, 25, 1);
  transform: scale(1);
  animation: pulse 2s 50ms;
}

/* Pulse effect on error */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(156, 33, 25, 0.8);
  }

  50% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(156, 33, 25, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(156, 33, 25, 0);
  }
}

@media (max-width: 425px) {
  .wdgt-product {
    width: 50%;
  }

  th,
  td {
    padding: 2px;
  }

  td.quantity button {
    display: none;
  }
}

.wdgt-form {
  display: flex;
  flex-wrap: wrap;
}

.form-field {
  margin-bottom: 15px;
  width: 50%;
  padding-right: 15px;
}

.form-field > label {
  display: block;
  margin-bottom: 5px;
}

.form-field.checkbox > label {
  display: inline;
  margin-left: 5px;
}

.checkout-buttons {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .form-field {
    width: 100%;
  }

  .checkout-buttons {
    flex-direction: column;
    justify-items: center;
    margin-top: 10px;
  }

  .checkout-buttons button {
    width: 100%;
    margin: 0 0 15px 0;
  }
}

@media (max-width: 425px) {
  input {
    width: 100%;
    padding: 5px;
  }
}
.wdgt-price .has-discount{
  text-decoration:line-through;
  color:#333 !important;
  font-weight: normal !important;
}
.wdgt-price-discount{
  color: #a81010;
  white-space: nowrap;
}

button.basket-button,
button.updateButton{
  background-color: #c91919;
  color: white;
  font-size: 20px;
  padding: 10px 40px 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
button.checkout{
  background: #acacac;
  color: white;
  font-size: 20px;
  padding: 10px 40px 10px 40px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 1rem;
}
.wdgt-quantity button,
.wdgt-quantity input{
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 5px 10px;
}
input{
  font-size: 18px;
  padding: 10px;
  margin: 0 0;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #dedede;
  width: 100%;
}
.checkbox input{
  width: auto;
}
.checkbox label {
  line-height: 25px;
}
.wdgt-form .checkbox label:before{
  background-color: #fafafa;
  border: 1px solid #dedede;
  border-radius: 4px;
  top: 0;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
  .wdgt-quantity button{
    display: none;
  }
  .payment-buttons button,
  .checkout-buttons button{
    width: 100% !important;
    max-width: 100% !important;
    align-items: center;
    justify-content: center;
  }
  .payment-buttons{
    display: block !important;
  }
  .updateButton{
    margin-top: 1rem !important;
  }
  button.checkout{
    margin: 0;
  }
}

.form-field span.error {
  display: block;
  margin-bottom: 0.5em;
  font-size: .75em;
  color: #f22435;
}
button:disabled{
  opacity: 0.6;
  cursor: not-allowed;
}

</style>
