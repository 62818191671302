<template>
  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-basket">
    <a v-if="order.orderDetails.length" @click="showBasket()" class="menu-item clickable"><i class="material-icons-round" style="vertical-align: -6px;">shopping_basket</i> {{ $t('words.basket') }} ({{ countBasketItems() }})</a>
    <a v-else @click="showBasket()" class="menu-item clickable"><i class="material-icons-round" style="vertical-align: -6px;">shopping_basket</i> {{ $t('words.basket') }} </a>
  </li>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  components: { },
  props: {
    // style: { type: String, required: false, default: null }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('basket', ['order'])
  },
  methods: {
    showBasket() {
      window.dispatchEvent(new CustomEvent('bambuyo:basket'));
    },
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    },
    countBasketItems() {
      let num = 0
      if (this.order.orderDetails.length) {
        this.order.orderDetails.forEach(item => {
          num += item.quantity
        })
      }
      return num;
    }
  }
}
</script>

<style scoped>
  .menu-item {
    color: #fff;
    border-color: #fff;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 44px;
  }

  .clickable {
    cursor: pointer;
  }
</style>
