import { BaseModel } from './BaseModel';
export class PostalAddress extends BaseModel{
  id;
  city = "";
  country = "";
  postalCode = "";
  region = "";
  street = "";
  streetNumber = "";

  constructor(data){
    super();
    if(!data){
      return;
    }
    let mappableFields = ['id', 'city', 'country', 'postalCode', 'region', 'street', 'streetNumber'];
    this.mapFields(data, mappableFields);
  }

  isEmpty(){
    return !(this.city.length || this.country.length || this.postalCode.length ||this.region.length || this.street.length)
  }
}