import request from '@/utils/request'
import { RequestParams } from '@/api/utils/requestUtils'

const publicApi = '/public/orders';
const privateApi = '/private/orders';

export default class OrderRepository {
  static find(filter, sort, paging) {
    const requestParams = new RequestParams(filter, sort, paging)
    return request({
      url: requestParams.buildUrl(publicApi),
      method: 'get'
    })
  }

  static customerOrders(filter, sort, paging) {
    const requestParams = new RequestParams(filter, sort, paging)
    return request({
      url: requestParams.buildUrl(privateApi),
      method: 'get'
    })
  }

  static findById(orderId) {
    return request({
      url: publicApi + '/' + orderId,
      method: 'get'
    })
  }

  static async getPaymentData(orderId) {
    return request(publicApi + '/' + orderId + '/paymentData')
  }

  static create(data) {
    return request({
      url: publicApi,
      method: 'post',
      data
    })
  }

  static delete(orderId) {
    return request({
      url: publicApi + '/' + orderId,
      method: 'delete'
    })
  }

}
