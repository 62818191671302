/* eslint-disable no-unused-expressions */
import { loadScript } from '@/utils/dom';
import Swal from "sweetalert2";

export default class Login {
  app

  constructor (app) {
    this.app = app
  }

  login () {
    console.error('login method must be implemented')
  }

  async loadScript (id, src, callback) {
    await loadScript(id, src)
    if (callback) {
      callback()
    }
  }

  deleteScript (id) {
    const script = document.head.querySelector('script#' + id)
    if (script) {
      script.remove()
    }
  }

  addMetatag (name, content) {
    const node = document.createElement('meta')
    node.name = name
    node.content = content
    document.head.appendChild(node)
  }

  async logout () {
    await this.app.$auth.logout()
    if (this.app.$jwtAutoreloader) {
      this.app.$jwtAutoreloader.reset()
    }
    window.dispatchEvent(new CustomEvent('logout'))
    window?.$nuxt?.$emit('login:logout')
    const callback = () => {
      this.app.store.commit('podcaster/clear')
      delete this.app.$auth.$state.redirect
    }
    this.app.router.push('/', callback)
  }

  onFailure (error) {
    //this.app.store.commit('environment/setLoggingIn', false)
    let message
    switch (error?.response?.status) {
      case 403:
        message = this.app.$i18n.t('messages.wrongCredentials');
        return
      case 409:
        message = this.app.$i18n.t('messages.loginError', [ '<a href="#" onclick="document.getElementById(\'signup-container\').__vue__.showComponent(\'recoverPasswordForm\')">', '</a>' ]);
        return
      default:
        console.error(error)
        message = this.app.$i18n.t('messages.genericError');
    }
    Swal.fire({
      title: 'Error!',
      text: message,
      icon: 'error',
      confirmButtonText: 'Ok'
    });
  }

  async onLoginSuccessful () {
    try {
      if (this.app.$jwtAutoreloader) {
        this.app.$jwtAutoreloader.reset()
      }
      await this.app.store.dispatch('podcaster/loadData')
      window.dispatchEvent(new CustomEvent('login'))
      // window.$nuxt is not ready when using autologin
      window?.$nuxt?.$emit('login:login')
    } catch (err) {
      console.error(err)
      const message = this.app.i18n.t('messages.genericError')
      Swal.fire({
        title: 'Error!',
        text: message,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
      this.app.$auth.logout()
    }
    this.app.store.commit('environment/setLoggingIn', false)
  }
}
