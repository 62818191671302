<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div :class="'modal-container ' + size">

          <div class="modal-header">
            <slot name="header"></slot>
          </div>

          <div class="modal-body">
            <slot name="body"></slot>
          </div>

          <div class="modal-footer" v-if="!hideFooter">
            <slot name="footer">
              <!-- button class="modal-default-button" @click="$emit('close')">
                OK
              </button -->
            </slot>
          </div>

          <i class="material-icons-round top-btn-close" @click="$emit('close')">close</i>
          <a class="bambuyo-link" href="https://bambuyo.com/" target="_blank">® BamBuyo</a>

        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  props: {
    size: { type:String, required: false, default: 'sm' },
    hideFooter: { type:Boolean, required: false, default: false }
  },
  data() {
    return {}
  }
}
</script>
<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 1005;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-container {
    margin: 0 auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    position: relative;
  }

  .bambuyo-link {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: .8rem;
    color: #aaa;
    text-decoration: none;
  }

  .modal-container.sm {
    max-width: 300px;
  }
  .modal-container.md {
    max-width: 500px;
  }
  .modal-container.lg {
    max-width: 800px;
  }
  .modal-container.xl {
    max-width: 1000px;
  }
  @media only screen and (max-width: 300px) {
    .modal-container {
      padding: 10px 30px;
    }
    .modal-container.sm {
      width: 100%;
    }
    .modal-container.md {
      width: 100%;
    }
    .modal-container.lg {
      width: 100%;
    }
    .modal-container.xl {
      width: 100%;
    }
  }
  @media only screen and (max-width: 500px) {
    .modal-container.md {
      width: 100%;
    }
    .modal-container.lg {
      width: 100%;
    }
    .modal-container.xl {
      width: 100%;
    }
  }
  @media only screen and (max-width: 800px) {
    .modal-container.lg {
      width: 100%;
    }
    .modal-container.xl {
      width: 100%;
    }
  }
  @media only screen and (max-width: 800px) {
    .modal-container.xl {
      width: 100%;
    }
  }

  .modal-header h3 {
    margin: 0;
    padding: 0;
    font-size: 23px;
  }

  .modal-body {
    margin: 20px 0;
    max-height: calc(100vh - 210px);
    /* max-width: calc(100vw - 70px); */
    overflow-y: auto;
    overflow-x: auto;
  }

  .modal-default-button {
    float: right;
  }

  .top-btn-close {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */
  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
