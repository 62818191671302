import {BaseModel} from './BaseModel';

export class Product extends BaseModel {
  id;
  licenseId;
  taxId;
  bookingCalendarId;
  name;
  description;
  price = 0;
  type = 'regular'; //regular/bookable
  duration = 0;
  status = 'active';
  supplier;

  constructor(data){
    super();
    if(!data) {
      return;
    }
    let mappableFields = ['id', 'licenseId', 'taxId', 'bookingCalendarId', 'name', 'description', 'price', 'type', 'duration', 'status', 'imageFile','supplier'];
    this.mapFields(data, mappableFields);
  }

}

export class Booking extends BaseModel {
  id;
  licenseId;
  orderDetailId;
  bookingCalendarId;
  startsAt;
  endsAt;
  status;
  bookingsAvailable;
  bookingsOccupied;
  constructor(data){
    super();
    if(!data) {
      return;
    }
    let mappableFields = ['id', 'licenseId', 'orderDetailId', 'bookingCalendarId', 'startsAt', 'endsAt', 'status', 'bookingsAvailable', 'bookingsOccupied'];
    this.mapFields(data, mappableFields);
  }

  getPrettyEndsAt(){
    return new Date( Date.parse(this.endsAt) + 1000 ).toISOString()
  }
}

export class OrderDetail extends BaseModel {
  id;
  licenseId;
  productId;
  quantity = 1;
  discountQuantity = 0;
  discountType = 'percentage';
  status = 'active';
  priceWithoutTaxes = 0;
  product = new Product();
  booking = null;

  constructor(data){
    super();
    if(!data) {
      return;
    }
    let mappableFields = ['id', 'licenseId', 'productId', 'quantity', 'discountQuantity', 'discountType', 'status', 'priceWithoutTaxes'];
    this.mapFields(data, mappableFields);
    if(!data){
      return;
    }
    //this.priceWithoutTaxes /= this.quantity
    this.mapProduct(data.product);
    this.mapBooking(data.booking);
  }

  setProduct(product){
    this.product = product
    this.licenseId = product.licenseId
    this.productId = product.id
    this.priceWithoutTaxes = product.price
  }

  setBooking(booking){
    this.booking = booking
  }

  mapProduct(product){
    this.product = new Product(product);
  }

  mapBooking(booking){
    if(booking) {
      this.booking = new Booking(booking);
    }
  }

}
