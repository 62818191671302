const lang = {
    words: {
        id: 'Id',
        license: 'License',
        username: 'Username',
        password: 'Contrasenya',
        rePassword: 'Repeteix la contrasenya',
        rememberMe: 'Recorda\'m en aquest dispositiu',
        basket: 'Cistella',
        vat: 'Num. fiscal (DNI, CIF, NIE)',
        company: 'Empresa',
        name: 'Nombre',
        surname: 'Apellidos',
        email: 'Email',
        phone: 'Teléfono',
        street: 'Calle',
        number: 'Número',
        city: 'Población',
        state: 'Provincia',
        postalCode: 'Codi postal',
        country: 'País',
        streetNumber: 'Número',
        total: 'Total',
        taxBase: 'Base imposable',
        optional: 'Opcional',
        attachments: 'Adjunts',
        available: 'Disponible',
        product: 'Producte',
        productsCount: ' 1 producte | {count} productes',
        generateInvoice: 'Sol·licitar factura (opcional)',
        privacyPolicy: 'Política de privadesa.',
        termsAndConditions: 'Avís legal',
        beforeTermsAndConditions: 'Accepto les',
        beforePrivacyPolicy: 'Accepto la',
        beforePrivacyPolicys: 'Accepto els',
        generalConditions: 'Condicions generals i d\'ús',
        andThe: 'i l\'',
        voucher: 'Val',
        comment: 'Comentari',
        code: 'Codi',
        occupied: 'Ocupades',
        maximum: 'Máxim',
        quant: 'Quant.',
        price: 'Preu',
        minuts: 'Minuts',
        newCustomer: 'Ets un client nou?',
        startHere: 'Comença aquí',
        registeredCustomer: 'Ja tens un compte?',
        recoveryPassword: 'Introduïu l\'adreça de correu electrònic per recuperar la contrasenya',
        newPassword: 'Introduïu una nova contrasenya',
        date: 'Data',
        status: 'Estat',
        paymentDate: 'Data de pagament',
        paymentMethod: 'Métode de pagament',
        orderDetails: 'Detalls de la comanda',
        refundDetails: 'Detalls de la devolució',
        unitPrice: 'Preu unitat',
        quantity: 'Quantitat',
        discount: 'Descompte',
        edit: 'Editar',
        delete: 'Eliminar',
        save: 'Desar',
        cancel: 'Cancel·lar',
        close: 'Tancar',
        modify: 'Modificar',
        privacyPolicyLink: '/politica-de-venda-i-cancellacio-devolucio/',
        termsAndConditionsText: 'termes i condicions',
    },
    messages: {
        loading: 'Carregant...',
        xOfYAvailable: 'disponibles {x} de {y}',
        selectBookingdate: 'Selecciona la data de la reserva',
        signinIn: 'Iniciant sessió',
        loginFail: 'Error en iniciar sessió',
        loginFailMessage: 'Verifiqueu les credencials i torneu-ho a provar',
        fileIsNotImage: 'L\'arxiu no és una imatge',
        paymentOK: 'Comanda pagada!',
        paymentKO: 'No s\'ha pogut realitzar el pagament.',
        paymentOKText: 'Enviarem al correu electronic facilitat, la confirmació de comanda. Fins aviat.',
        iHaveATicketOrGiftVoucher: 'Tinc un abonament o xec regal',
        writeHerePlaceholder: 'Escrigui aquí ...',
        availableBookings: 'Reserves disponibles',
        amountExceeded: 'Quantitat superada',
        amountExceededText: 'Queden {maxUnits} reserves disponibles per aquesta data i vostè vol reservar-ne {quantity}. Si necessita fer més reserves, haurà de buscar un altre data amb més disponibilitat.',
        incorrectCode: 'Codi incorrecte',
        incorrectCodeText: 'Siusplau comprovi el codi introduït',
        validateYourEmail: 'Valideu el vostre correu electrònic a través del correu electrònic que heu rebut al vostre compte.',
        registrationError: 'No s\'ha pogut registrar l\'usuari.',
        registrationFail: 'Registre sense èxit',
        registrationFailMessage: 'Comprova les teves dades i torna-ho a provar',
        customerAlreadyExists: 'Sembla que ja estàs registrat!',
        passwordConfirmationNotMatch: 'La confirmació de la contrasenya no coincideix',
        sentEmailRecovery: 'Email enviat',
        sentEmailRecoveryText: 'T\'hem enviat un correu electrònic per recuperar la teva contrasenya.',
        sentEmailRecoveryFail: 'S\'ha produït un error.',
        sentEmailRecoveryFailText: 'Torneu-ho a provar més tard.',
        emailNotExists: 'L\'email no existeix.',
        emailAlreadyExists: 'L\'email ja existeix.',
        emptyBasket: 'No hi ha productes a la cistella.',
        noBookingPossible: 'No es pot reservar',
        theBookingOf: 'La reserva de ',
        theDay: ' el dia ',
        noFurtherBookingsAccepted: 'no admet més reserves. Siusplau, reservi de nou en un altre horari.',
        errorCreatingOrder: 'Error creant la comanda',
        thereSeemsToHaveAProblemWhenPlacingOrder: 'Sembla que s\'ha produït un error al crear la comanda. Provi-ho de nou en un altre moment o contacti amb nosaltres.',
        bookingConfirmed: 'Reserva confirmada',
        bookingIsConfirmedText: 'Confirmem que la seva reserva s\'ha registrat correctament.',
        giftVouchersAreIndividual: 'El xec regal que esta utilizant és individual, no pot fer-lo servir per a més d\'una reserva en una mateixa hora.',
        errorTryingToBook: 'Error intentant reservar',
        errorTryingToBookMessage: 'Ha surgit un problema al realitzar aquesta reserva. Provi-ho de nou en un altre moment o contacti amb nosaltres.',
        emptyOrders: 'No hi ha comandes',
        emptyModifiableBookings: 'No hi ha reserves modificables',
        cancellationRequest: 'Per a sol·licitar la cancel·lació d\'una comanda contactar a través de l\'email',
        cancellationRequest2: '.(No es podrà cancel·lar la visita abans de 24h)',
        soldOut: 'Esgotat',
        lastUnits: 'Últimes unitats',
        available: 'Disponible',
        notModifiable: 'No modificable',
        bookingDateChanged: 'Data de reserva modificada',
        chooseTheTourYouWantToTake: 'Escull la visita que vols fer i tria una data. Si tens un xec regal',
        checkHereAndMakeYourReservation: 'comprova\'l aquí i fes la teva reserva',
        enterTheCode: 'Introdueix el codi',
        highAvailavility: 'Disponibilitat alta',
        mediumAvailavility: 'Disponibilitat mitja',
        noAvailavility: 'No hi ha disponibilitat',
        acceptTheTerms: 'Accepta les condicions',
        haveToAcceptTheTerms: 'Per procedir amb la compra, has d\'acceptar les condicions de venda i la política de privacitat.',
        missingPaymentEntries:  'Falten entrades de pagament',
        guidedTours: 'La reserva de visites guiades gratuïtes està vinculada a la compra d\'entrades de pagament. Si us plau, afegeix entrades de pagament a la cistella per poder continuar amb la compra.',
        ok: 'D\'acord',
        myProfile: 'El meu perfil',
        modifyBookings: 'Modificar reserves',
        myOrders: 'Les meves comandes',
        changePassword: 'Canviar contrasenya',
        logOut: 'Tancar sessió',
        order: 'Comanda',
        dayExpires: 'Caduca el día',
        bookTheDay: 'Reserva el dia',
        bookingByDate: 'Reservant per la data',
        expiredOnDay: 'Val caducat el dia',
        valExhausted: 'Val exhaurit',
        of: 'de',
        calendar: 'Calendari',
        invoice: 'Factura',
        downloadInvoice: 'Descarregar factura',
        viewOrder: 'Veure comanda',
        iForgotMyPassword: 'He oblidat la contrasenya',
        areYouANewCustomer: 'Ets un client nou?',
        startHere: 'Comença aquí',
    },
    actions: {
        continueShopping: 'Continuar comprant',
        add: 'Afegir',
        addToBasket: 'Afegir a la cistella',
        pay: 'Pagar',
        book: 'Reservar cita',
        retry: 'Reintentar',
        paymentRetry: 'Reintentar el pagament',
        backToBasket: 'Tornar a la cistella',
        checkVoucher: 'Comprovar codi',
        cancel: 'Cancel·lar',
        readMore: 'Llegir més',
        readLess: 'Llegir menys',
        accept: 'Acceptar',
        login: 'Iniciar sessió',
        recoverAccount: 'Recuperar compte',
        forgottenPassword: 'Has oblidat la contrasenya?',
        register: 'Registrar',
        send: 'Enviar',
        myAccount: 'El meu compte',
        check: 'Comprova',
        toBook: 'Reserva',
        buy:'Compra',
        loginWithGoogle: 'Accedir amb Google',
        loginWithFacebook: 'Accedir amb Facebook',
        loginWithMicrosoft: 'Accedir amb Microsoft',
        loginWithApple: 'Accedir amb Apple',
    },
    _eventStatus: {
        reservable: 'Reservable',
        exceptionally_closed: 'Excepcionalment tancat'
    },
    _orderStatus: {
        awaiting_payment: 'Pendent',
        draft: 'Esborrany',
        paid: 'Pagat',
        refunded: 'Reemborsat'
    },
    _paymentMethod: {
        null: '',
        cash: 'Efectiu',
        online: 'Online',
        card: 'Targeta',
        transfer: 'Transferència'
    },
    paymentError: {
        null: 'Error desconegut',
        general_0: 'Les signatures no coincideixen, possible frau',
        redsys_0101: 'Targeta caducada',
        redsys_0102: 'Targeta a excepció transitòria o sota sospita de frau',
        redsys_0106: 'Intents de PIN excedits',
        redsys_0125: 'Targeta no efectiva',
        redsys_0129: 'Codi de seguretat (CVV2/CVC2) incorrecte',
        redsys_0180: 'Targeta aliena al servei',
        redsys_0184: 'Error a l\'autenticació del titular',
        redsys_0190: 'Denegació de l\'emissor sense especificar motiu',
        redsys_0191: 'Data de caducitat errònia',
        redsys_0195: 'Requereix autenticació SCA',
        redsys_0202: 'Targeta a excepció transitòria o sota sospita de frau amb retirada de targeta',
        redsys_0904: 'Comerç no registrat a FUC',
        redsys_0909: 'Error del sistema',
        redsys_0913: 'Comanda repetida',
        redsys_0944: 'Sessió incorrecta',
        redsys_0950: 'Operació de devolució no permesa',
        redsys_9912: 'Emissor no disponible',
        redsys_0912: 'Emissor no disponible',
        redsys_9064: 'Nombre de posicions de la targeta incorrecte',
        redsys_9078: 'Tipus d\'operació no permesa per a aquesta targeta',
        redsys_9093: 'Targeta no existent',
        redsys_9094: 'Rebuig dels servidors internacionals',
        redsys_9104: 'Comerç amb titular segur i titular sense clau de compra segura',
        redsys_9218: 'El comerç no permet op. segures per entrada /operacions',
        redsys_9253: 'Targeta no compleix el check-digit',
        redsys_9256: 'El comerç no pot fer preautoritzacions',
        redsys_9257: 'Aquesta targeta no permet operativa de preautoritzacions',
        redsys_9261: 'Operació detinguda per superar el control de restriccions a l\'entrada al SIS',
        redsys_9915: 'A petició de l\'usuari s\'ha cancel·lat el pagament',
        redsys_9997: 'S\'està processant una altra transacció a SIS amb la mateixa targeta',
        redsys_9998: 'Operació en procés de sol·licitud de dades de targeta',
        redsys_9999: 'Operació que ha estat redirigida a l\'emissor a autenticar',
    },
    forms:{
        required: 'Aquest camp és obligatori',
        dninifcif: 'Aquest camp ha de tenir un format de CIF/NIF/NIE vàlid.',
        email: 'Aquest camp ha de tenir un format d\'email vàlid.',
        phone: 'El telèfon ha de contenir 9 digits sense espais',
    },
    errors: {
        1000002: 'Error a l\'iniciar sessió, Email o contrasenya incorrectes.',
        1000030: 'L\'email no està validat.',
        1000032: 'L\'email {email} no existeix. Siusplau registra\'t.',
        1000011: 'El correu electrònic {email} ja està registrat. Siusplau inicia sessió.'
    }
};

Object.assign(lang.words, {
    Image: lang.words.image,
    Name: lang.words.name,
    Notes: lang.words.notes,
    Surnames: lang.words.surname,
    companyName: lang.words.company,
    contactEmail: lang.words.email,
    contactPhone: lang.words.phone,
    contactGender: lang.words.gender,
    contactBirthdate: lang.words.birthdate
});

export default lang;
