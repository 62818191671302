import request from '@/utils/request'
import { RequestParams } from '@/api/utils/requestUtils'

const publicApi = '/public/bookings';
const privateApi = '/private/bookings';

export default class OrderRepository {
  static find(filter, sort, paging) {
    const requestParams = new RequestParams(filter, sort, paging)
    return request({
      url: requestParams.buildUrl(publicApi),
      method: 'get'
    })
  }

  static customerBookings(filter, sort, paging) {
    const requestParams = new RequestParams(filter, sort, paging)
    return request({
      url: requestParams.buildUrl(privateApi),
      method: 'get'
    })
  }

  static findById(bookingId) {
    return request({
      url: publicApi + '/' + bookingId,
      method: 'get'
    })
  }

  static create(data) {
    return request({
      url: publicApi,
      method: 'post',
      data
    })
  }

  static delete(bookingId) {
    return request({
      url: publicApi + '/' + bookingId,
      method: 'delete'
    })
  }

  static changeDate(id, startsAt) {
    return request({
      url: privateApi + '/' + id + '/change-date',
      method: 'patch',
      data: {
        startsAt: startsAt
      }
    })
  }

}
