<template>
  <div>
    <div v-if="!forgetPassword" id="login-container" class="wdgt-container" ref="container">
      <div class="social-logins">
        <button class="btn-social-login btn-google-login">
          <i class="fab fa-google"></i>
          <span class="d-none d-sm-inline d-md-none d-lg-inline ml-1">{{ $t('actions.loginWithGoogle') }}</span>
          <span ref="googleLoginButton" id="googleLoginButton" class="google-login-container" />
        </button>
        <button id="facebookLoginButton" class="btn-social-login btn-facebook-login" @click="facebookLogin.login()">
          <i class="fab fa-facebook"></i>
          <span class="d-none d-sm-inline d-md-none d-lg-inline ml-1">{{ $t('actions.loginWithFacebook') }}</span>
        </button>
        <!--button class="btn-social-login btn-microsoft-login">
          <i class="fab fa-microsoft"></i>
          <span class="d-none d-sm-inline d-md-none d-lg-inline ml-1">{{ $t('actions.loginWithMicrosoft') }}</span>
        </button>
        <button class="btn-social-login btn-apple-login">
          <i class="fab fa-apple"></i>
          <span class="d-none d-sm-inline d-md-none d-lg-inline ml-1">{{ $t('actions.loginWithApple') }}</span>
        </button-->
      </div>
      <hr>
      <form ref="login-form" @submit.stop.prevent="loginForm">
        <div class="login-form">
          <div class="form-field">
            <!--label for="login-email">{{ $t('words.email') }}</label-->
            <div>
              <input id="login-email" v-model="loginCredentials.username" autocomplete="email" type="email" :min="3" name="username" :placeholder="$t('words.email')"
                     @invalid="onInvalid" required>
            </div>
          </div>
          <div class="form-field">
            <!--label for="login-password">{{ $t('words.password') }}</label-->
            <div>
              <input id="login-password" v-model="loginCredentials.password" autocomplete="current-password" type="password" :min="5" name="password" :placeholder="$t('words.password')" @invalid="onInvalid" required>
            </div>
          </div>
        </div>
        <div class="login-buttons">
          <div class="left-column">
            <span class="st-link" @click="forgetPassword=true">{{ $t('messages.iForgotMyPassword') }}</span>
          </div>
          <div class="right-column">
            <button type="submit" class="loginButton green">
              {{ $t('actions.login') }}
            </button>
          </div>
        </div>
      </form>
      <div class="login-links">
        <p>{{ $t('messages.areYouANewCustomer')}} <span class="st-link" @click="$emit('show-register')">{{ $t('messages.startHere')}}</span></p>
      </div>
    </div>
    <div v-if="forgetPassword" id="recovery-container" class="wdgt-container" ref="container">
      <div class="mb-3">
        {{ $t('words.recoveryPassword') }}
      </div>
      <form ref="recovery-form" @submit.stop.prevent="recoveryForm">
        <div class="login-form">
          <div class="form-field">
            <label for="login-email">{{ $t('words.email') }}</label>
            <div>
              <input
                  id="login-email"
                  v-model="contactEmail"
                  autocomplete="email"
                  type="email"
                  :min="3"
                  name="username"
                  :placeholder="$t('words.email')"
                  @invalid="onInvalid"
                  required
              />
            </div>
          </div>
        </div>
        <div class="login-buttons">
          <button type="submit" class="loginButton green d-flex">
            {{ $t('actions.send') }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import { LoginCredentials } from '@/models/LoginCredentials';
import Swal from "sweetalert2";
import {i18n} from "@/lang";
import {Customer} from "@/models/Customer";
import CustomerRepository from "@/api/CustomerRepository";
import GoogleLogin from "@/plugins/auth/google";
import FacebookLogin from "@/plugins/auth/facebook";

export default
{
  props: {
    forgetPassword: { type:Boolean, required: true},
  },
  data() {
    return {
      loginCredentials: new LoginCredentials(),
      newCustomer: new Customer(),
      contactEmail: '',
      googleLogin: new GoogleLogin(this),
      facebookLogin: new FacebookLogin(this),
    }
  },
  computed: {
    ...mapGetters('basket', ['orderDetails']),
    ...mapGetters('session', ['customer']),
  },
  methods: {
    ...mapActions('session', ['login']),
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    },
    onInvalid(ev) {
      ev.target.classList.add('touched')
    },
    async loginForm() {
      this.loginCredentials.license = this.getLicense();
      try {
        await this.login(this.loginCredentials);
        if (this.orderDetails.length > 0) {
          this.$emit('show-basket');
        } else {
          this.$emit('close-modal');
        }
      } catch (err) {
        if (err.response.data.code === 1000002) {
          await Swal.fire({
            title: i18n.t('messages.loginFail'),
            text: i18n.t('errors.1000002'),
            icon: 'error',
            showCancelButton: false
          });
        }else if(err.response.data.code === 1000030){
          await Swal.fire({
            title: i18n.t('errors.1000030'),
            text: i18n.t('messages.validateYourEmail'),
            icon: 'error',
            showCancelButton: false
          });
        }else if(err.response.data.code === 1000011){
          await Swal.fire({
            title: i18n.t('errors.1000011',{email: this.loginCredentials.username}),
            text: i18n.t('messages.validateYourEmail'),
            icon: 'error',
            showCancelButton: false
          });
        }else{
          await Swal.fire({
            title: i18n.t('messages.loginFail'),
            text: i18n.t('messages.loginFailMessage'),
            icon: 'error',
            showCancelButton: false
          });
        }
      }
    },
    async recoveryForm() {
      try {
        this.newCustomer = await CustomerRepository.recover(this.contactEmail);
        await Swal.fire({
          title: i18n.t('messages.sentEmailRecovery'),
          text: i18n.t('messages.sentEmailRecoveryText'),
          icon: 'success',
          showCancelButton: false
        });
        this.forgetPassword = false;
        this.$emit('show-login');
      } catch (err) {
        if (err.response.data.code === 1000032) {
          await Swal.fire({
            title: i18n.t('messages.emailNotExists'),
            text: i18n.t('errors.1000032',{email:this.contactEmail}),
            icon: 'error',
            showCancelButton: false
          });
          this.forgetPassword = false;
          this.$emit('show-register');
        }else{
          await Swal.fire({
            title: i18n.t('messages.sentEmailRecoveryFail'),
            text: i18n.t('messages.sentEmailRecoveryFailText'),
            icon: 'error',
            showCancelButton: false
          });
        }
      }
    }
  },
  mounted() {
    const button = this.$refs.googleLoginButton;
    this.googleLogin.attachClickHandler(button);
  }
}
</script>
<style scoped>
button[type="button"],
button[type="submit"],
input[type=checkbox] {
  cursor: pointer;
}
input.touched:valid {
  /*
  border: inherit !important;
  outline: inherit !important;
  */
}

input.touched:invalid {
  border: 1px solid rgb(156, 33, 25) !important;
  outline: 1px solid rgb(156, 33, 25) !important;
  box-shadow: 0 0 0 0 rgba(156, 33, 25, 1);
  transform: scale(1);
  animation: pulse 2s 50ms;
}

/* Pulse effect on error */
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(156, 33, 25, 0.8);
  }

  50% {
    transform: scale(0.95);
    box-shadow: 0 0 0 10px rgba(156, 33, 25, 0);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(156, 33, 25, 0);
  }
}

#login-container form {
  margin-bottom: 1rem;
}

.form-field {
  margin-bottom: 15px;
  width: 100%;
  padding-right: 15px;
}

.form-field > label {
  display: block;
  margin-bottom: 5px;
}

.form-field.checkbox > label {
  display: inline;
  margin-left: 5px;
}

.login-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-buttons button {
  display: inline;
}

.login-links {
  margin-top: 20px;
  text-align: center;
}
@media (max-width: 768px) {
  .form-field {
    width: 100%;
  }

  .login-buttons button {
    width: 100%;
    margin: 0 0 15px 0;
  }
}

@media (max-width: 425px) {
  input {
    width: 100%;
    padding: 5px;
  }
}
button.loginButton {
  background-color: #000;
  color: white;
  font-size: 1rem;
  padding: 8px 40px 8px 40px;
  border: none;
  border-radius: 2rem;
  cursor: pointer;
  text-transform: uppercase;
}
input {
  font-size: 18px;
  padding: 10px;
  border: 0;
  margin: 0;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  width: 100%;
}

input::placeholder {
  font-size: inherit;
  color: #000;
}

.checkbox input{
  width: auto;
}
.checkbox label {
  line-height: 25px;
}
.wdgt-form .checkbox label:before{
  background-color: #fafafa;
  border: 1px solid #dedede;
  border-radius: 4px;
  top: 0;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (max-width: 768px) {
  .login-buttons button {
    width: 100% !important;
    max-width: 100% !important;
  }
  .loginButton{
    margin-top: 1rem !important;
  }
}

.btn-social-login {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  margin: 0.5rem 0;
  cursor: pointer;
  width: 100%;
}
.btn-google-login {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
}
.btn-facebook-login {
  background-color: #3b5998;
  color: #fff;
  border: 1px solid #3b5998;
}
.btn-microsoft-login {
  background-color: #f25022;
  color: #fff;
  border: 1px solid #f25022;
}
.btn-apple-login {
  background-color: #000;
  color: #fff;
  border: 1px solid #000;
}
.google-login-container{
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
  opacity: 0.01;
}

.left-column {
  flex: 1;
  text-align: left;
}

.right-column {
  flex: 1;
  text-align: right;
}
</style>
