<template>
  <div v-if="order" class="text-center">
    <div v-if="order.status === 'paid'">
      <h3>{{ $t('messages.paymentOK') }}</h3>
      <div class="swal2-icon swal2-success swal2-icon-show">
        <div class="swal2-success-circular-line-left" style="background-color: rgb(255, 255, 255);" />
        <span class="swal2-success-line-tip" />
        <span class="swal2-success-line-long" />
        <div class="swal2-success-ring" />
        <div class="swal2-success-fix" style="background-color: rgb(255, 255, 255);" />
        <div class="swal2-success-circular-line-right" style="background-color: rgb(255, 255, 255);" />
      </div>
      <div>
        {{ $t('messages.paymentOKText') }}
      </div>
    </div>
    <div v-else>
      <div>
        <h3>{{ $t('messages.paymentKO') }}</h3>
        <p>{{ $t('paymentError.' + paymentError) }}</p>
      </div>
      <div class="swal2-icon swal2-error swal2-icon-show" style="display: flex;">
        <span class="swal2-x-mark">
          <span class="swal2-x-mark-line-left"></span>
          <span class="swal2-x-mark-line-right"></span>
        </span>
      </div>
      <button
          @click="pay(orderId)"
          type="button"
          class="green d-flex"
      >
        <i class="material-icons-round">payment</i>
        {{ $t('actions.paymentRetry') }}
      </button>
      <button
          @click="$emit('cancel')"
          type="button"
          class="grey d-flex"
      >
        {{ $t('actions.cancel') }}
      </button>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import OrderRepository from '@/api/OrderRepository'

export default {
  props: {
    orderId: { type:String, required: true },
    paymentError: { type:String, required: false }
  },
  data () {
    return {
      order: null
    }
  },
  mounted () {
    this.fillOrder()
  },
  methods: {
    ...mapActions('basket', ['clear', 'pay']),
    async fillOrder(){
      try {
        const response = await OrderRepository.findById(this.orderId)
        this.order = response.data
        if (this.order.status === 'paid') {
          this.clear()
        }
      } catch (err) {
        this.clear()
      }
    }
  }
}
</script>

<style scoped>
button{
  margin: 15px auto 0;
}

div#_pg-app *.swal2-icon,
div#_pg-app *.swal2-icon > *{
  box-sizing: content-box;
}

.swal2-popup.swal2-toast .swal2-icon {
    width: 2em;
    min-width: 2em;
    height: 2em;
    margin: 0
}

.swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
    display: flex;
    align-items: center;
    font-size: 1.8em;
    font-weight: 700
}

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active) {
    .swal2-popup.swal2-toast .swal2-icon .swal2-icon-content {
        font-size: .25em
    }
}

.swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
    width: 2em;
    height: 2em
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    top: .875em;
    width: 1.375em
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
    left: .3125em
}

.swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
    right: .3125em
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-tip {
    -webkit-animation: swal2-toast-animate-success-line-tip .75s;
    animation: swal2-toast-animate-success-line-tip .75s
}

.swal2-popup.swal2-toast .swal2-success.swal2-icon-show .swal2-success-line-long {
    -webkit-animation: swal2-toast-animate-success-line-long .75s;
    animation: swal2-toast-animate-success-line-long .75s
}

.swal2-icon {
    position: relative;
    box-sizing: content-box;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 1.25em auto 1.875em;
    border: .25em solid transparent;
    border-radius: 50%;
    font-family: inherit;
    line-height: 5em;
    cursor: default;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.swal2-icon .swal2-icon-content {
    display: flex;
    align-items: center;
    font-size: 3.75em
}

.swal2-icon.swal2-error {
    border-color: #f27474;
    color: #f27474
}

.swal2-icon.swal2-error .swal2-x-mark {
    position: relative;
    flex-grow: 1
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
    display: block;
    position: absolute;
    top: 2.3125em;
    width: 2.9375em;
    height: .3125em;
    border-radius: .125em;
    background-color: #f27474
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
    left: 1.0625em;
    transform: rotate(45deg)
}

.swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
    right: 1em;
    transform: rotate(-45deg)
}

.swal2-icon.swal2-error.swal2-icon-show {
    -webkit-animation: swal2-animate-error-icon .5s;
    animation: swal2-animate-error-icon .5s
}

.swal2-icon.swal2-error.swal2-icon-show .swal2-x-mark {
    -webkit-animation: swal2-animate-error-x-mark .5s;
    animation: swal2-animate-error-x-mark .5s
}

.swal2-icon.swal2-warning {
    border-color: #facea8;
    color: #f8bb86
}

.swal2-icon.swal2-info {
    border-color: #9de0f6;
    color: #3fc3ee
}

.swal2-icon.swal2-question {
    border-color: #c9dae1;
    color: #87adbd
}

.swal2-icon.swal2-success {
    border-color: #a5dc86;
    color: #a5dc86
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line] {
    position: absolute;
    width: 3.75em;
    height: 7.5em;
    transform: rotate(45deg);
    border-radius: 50%
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
    top: -.4375em;
    left: -2.0635em;
    transform: rotate(-45deg);
    transform-origin: 3.75em 3.75em;
    border-radius: 7.5em 0 0 7.5em
}

.swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
    top: -.6875em;
    left: 1.875em;
    transform: rotate(-45deg);
    transform-origin: 0 3.75em;
    border-radius: 0 7.5em 7.5em 0
}

.swal2-icon.swal2-success .swal2-success-ring {
    position: absolute;
    z-index: 2;
    top: -.25em;
    left: -.25em;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    border: .25em solid rgba(165,220,134,.3);
    border-radius: 50%
}

.swal2-icon.swal2-success .swal2-success-fix {
    position: absolute;
    z-index: 1;
    top: .5em;
    left: 1.625em;
    width: .4375em;
    height: 5.625em;
    transform: rotate(-45deg)
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
    display: block;
    position: absolute;
    z-index: 2;
    height: .3125em;
    border-radius: .125em;
    background-color: #a5dc86
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
    top: 2.875em;
    left: .8125em;
    width: 1.5625em;
    transform: rotate(45deg)
}

.swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
    top: 2.375em;
    right: .5em;
    width: 2.9375em;
    transform: rotate(-45deg)
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
    -webkit-animation: swal2-animate-success-line-tip .75s;
    animation: swal2-animate-success-line-tip .75s
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
    -webkit-animation: swal2-animate-success-line-long .75s;
    animation: swal2-animate-success-line-long .75s
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
    -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
    animation: swal2-rotate-success-circular-line 4.25s ease-in
}

[class^=swal2] {
    -webkit-tap-highlight-color: transparent
}

@supports (-ms-accelerator:true) {
    .swal2-range input {
        width: 100%!important
    }

    .swal2-range output {
        display: none
    }
}

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active) {
    .swal2-range input {
        width: 100%!important
    }

    .swal2-range output {
        display: none
    }
}

@-webkit-keyframes swal2-toast-show {
    0% {
        transform: translateY(-.625em) rotateZ(2deg)
    }

    33% {
        transform: translateY(0) rotateZ(-2deg)
    }

    66% {
        transform: translateY(.3125em) rotateZ(2deg)
    }

    100% {
        transform: translateY(0) rotateZ(0)
    }
}

@keyframes swal2-toast-show {
    0% {
        transform: translateY(-.625em) rotateZ(2deg)
    }

    33% {
        transform: translateY(0) rotateZ(-2deg)
    }

    66% {
        transform: translateY(.3125em) rotateZ(2deg)
    }

    100% {
        transform: translateY(0) rotateZ(0)
    }
}

@-webkit-keyframes swal2-toast-animate-success-line-tip {
    0% {
        top: .5625em;
        left: .0625em;
        width: 0
    }

    54% {
        top: .125em;
        left: .125em;
        width: 0
    }

    70% {
        top: .625em;
        left: -.25em;
        width: 1.625em
    }

    84% {
        top: 1.0625em;
        left: .75em;
        width: .5em
    }

    100% {
        top: 1.125em;
        left: .1875em;
        width: .75em
    }
}

@keyframes swal2-toast-animate-success-line-tip {
    0% {
        top: .5625em;
        left: .0625em;
        width: 0
    }

    54% {
        top: .125em;
        left: .125em;
        width: 0
    }

    70% {
        top: .625em;
        left: -.25em;
        width: 1.625em
    }

    84% {
        top: 1.0625em;
        left: .75em;
        width: .5em
    }

    100% {
        top: 1.125em;
        left: .1875em;
        width: .75em
    }
}

@-webkit-keyframes swal2-toast-animate-success-line-long {
    0% {
        top: 1.625em;
        right: 1.375em;
        width: 0
    }

    65% {
        top: 1.25em;
        right: .9375em;
        width: 0
    }

    84% {
        top: .9375em;
        right: 0;
        width: 1.125em
    }

    100% {
        top: .9375em;
        right: .1875em;
        width: 1.375em
    }
}

@keyframes swal2-toast-animate-success-line-long {
    0% {
        top: 1.625em;
        right: 1.375em;
        width: 0
    }

    65% {
        top: 1.25em;
        right: .9375em;
        width: 0
    }

    84% {
        top: .9375em;
        right: 0;
        width: 1.125em
    }

    100% {
        top: .9375em;
        right: .1875em;
        width: 1.375em
    }
}

@-webkit-keyframes swal2-show {
    0% {
        transform: scale(.7)
    }

    45% {
        transform: scale(1.05)
    }

    80% {
        transform: scale(.95)
    }

    100% {
        transform: scale(1)
    }
}

@keyframes swal2-show {
    0% {
        transform: scale(.7)
    }

    45% {
        transform: scale(1.05)
    }

    80% {
        transform: scale(.95)
    }

    100% {
        transform: scale(1)
    }
}

@-webkit-keyframes swal2-hide {
    0% {
        transform: scale(1);
        opacity: 1
    }

    100% {
        transform: scale(.5);
        opacity: 0
    }
}

@keyframes swal2-hide {
    0% {
        transform: scale(1);
        opacity: 1
    }

    100% {
        transform: scale(.5);
        opacity: 0
    }
}

@-webkit-keyframes swal2-animate-success-line-tip {
    0% {
        top: 1.1875em;
        left: .0625em;
        width: 0
    }

    54% {
        top: 1.0625em;
        left: .125em;
        width: 0
    }

    70% {
        top: 2.1875em;
        left: -.375em;
        width: 3.125em
    }

    84% {
        top: 3em;
        left: 1.3125em;
        width: 1.0625em
    }

    100% {
        top: 2.8125em;
        left: .8125em;
        width: 1.5625em
    }
}

@keyframes swal2-animate-success-line-tip {
    0% {
        top: 1.1875em;
        left: .0625em;
        width: 0
    }

    54% {
        top: 1.0625em;
        left: .125em;
        width: 0
    }

    70% {
        top: 2.1875em;
        left: -.375em;
        width: 3.125em
    }

    84% {
        top: 3em;
        left: 1.3125em;
        width: 1.0625em
    }

    100% {
        top: 2.8125em;
        left: .8125em;
        width: 1.5625em
    }
}

@-webkit-keyframes swal2-animate-success-line-long {
    0% {
        top: 3.375em;
        right: 2.875em;
        width: 0
    }

    65% {
        top: 3.375em;
        right: 2.875em;
        width: 0
    }

    84% {
        top: 2.1875em;
        right: 0;
        width: 3.4375em
    }

    100% {
        top: 2.375em;
        right: .5em;
        width: 2.9375em
    }
}

@keyframes swal2-animate-success-line-long {
    0% {
        top: 3.375em;
        right: 2.875em;
        width: 0
    }

    65% {
        top: 3.375em;
        right: 2.875em;
        width: 0
    }

    84% {
        top: 2.1875em;
        right: 0;
        width: 3.4375em
    }

    100% {
        top: 2.375em;
        right: .5em;
        width: 2.9375em
    }
}

@-webkit-keyframes swal2-rotate-success-circular-line {
    0% {
        transform: rotate(-45deg)
    }

    5% {
        transform: rotate(-45deg)
    }

    12% {
        transform: rotate(-405deg)
    }

    100% {
        transform: rotate(-405deg)
    }
}

@keyframes swal2-rotate-success-circular-line {
    0% {
        transform: rotate(-45deg)
    }

    5% {
        transform: rotate(-45deg)
    }

    12% {
        transform: rotate(-405deg)
    }

    100% {
        transform: rotate(-405deg)
    }
}

@-webkit-keyframes swal2-animate-error-x-mark {
    0% {
        margin-top: 1.625em;
        transform: scale(.4);
        opacity: 0
    }

    50% {
        margin-top: 1.625em;
        transform: scale(.4);
        opacity: 0
    }

    80% {
        margin-top: -.375em;
        transform: scale(1.15)
    }

    100% {
        margin-top: 0;
        transform: scale(1);
        opacity: 1
    }
}

@keyframes swal2-animate-error-x-mark {
    0% {
        margin-top: 1.625em;
        transform: scale(.4);
        opacity: 0
    }

    50% {
        margin-top: 1.625em;
        transform: scale(.4);
        opacity: 0
    }

    80% {
        margin-top: -.375em;
        transform: scale(1.15)
    }

    100% {
        margin-top: 0;
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes swal2-animate-error-icon {
    0% {
        transform: rotateX(100deg);
        opacity: 0
    }

    100% {
        transform: rotateX(0);
        opacity: 1
    }
}

@keyframes swal2-animate-error-icon {
    0% {
        transform: rotateX(100deg);
        opacity: 0
    }

    100% {
        transform: rotateX(0);
        opacity: 1
    }
}

@-webkit-keyframes swal2-rotate-loading {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}

@keyframes swal2-rotate-loading {
    0% {
        transform: rotate(0)
    }

    100% {
        transform: rotate(360deg)
    }
}
</style>
