import FileRepository from "@/api/FileRepository";

export function downloadFile(fileId) {
  FileRepository.download(fileId).then(response => {
    console.log(response);
    const url = URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', fileId)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

export function toQueryString(parametersObject, addQuestionMarkIfNeeded) {
  let str = [];
  for (const p in parametersObject) {
    str.push(encodeURIComponent(p) + "=" + encodeURIComponent(parametersObject[p]));
  }
  let queryString = str.join("&");
  if (addQuestionMarkIfNeeded && queryString.length > 0) {
    queryString = '?' + queryString;
  }
  return queryString;
}