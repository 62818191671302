<template>
  <img
    v-if="src"
    :src="src"
    :width="width"
    :height="height"
  >
</template>

<script>
import images from '@/api/Images';
import ImageFile from '@/models/ImageFile';

export default {
  props: {
    imageFile:{type:ImageFile, default:null},
    width:{type:Number, default:60},
    height:{type:Number, default:60}
  },
  data(){
    return {src:''};
  },
  created(){
    this.getSrc();
  },
  methods:{
    async getSrc(){
      if(!this.imageFile) {
        return '';
      }
      this.src = await images.getImage(this.imageFile.id, this.width, this.height);
    }
  }
}
</script>
