import { ModelImage } from "./ModelImage";
import { PostalAddress } from "./PostalAddress";

export class Customer extends ModelImage {
  id;
  licenseId = '';
  companyName = '';
  contactBirthdate = '';
  contactEmail = '';
  password = '';
  contactGender = '';
  contactName = '';
  contactPhone1 = '';
  contactPhone2 = '';
  contactSurname = '';
  customFieldValues = [];
  customerFiles = [];
  customerLegalDocuments = [];
  postalAddress = new PostalAddress();
  status = '';
  vat = '';
  notes = '';

  constructor(data){
    if(data instanceof Customer){
      return data
    }
    super();
    if(!data){
      return;
    }
    let mappableFields = ['id', 'customFieldValues', 'licenseId', 'companyName', 'contactBirthdate', 'contactEmail', 'password', 'contactGender', 'contactName', 'contactPhone1', 'contactPhone2', 'contactSurname', 'status', 'vat', 'notes'];
    this.mapFields(data, mappableFields);
    this.mapImageFile(data);
    this.mapPostalAddress(data);
    this.contactPhone1 = this.contactPhone1 ? this.contactPhone1.slice(3) : null;
  }

  mapPostalAddress(data){
    if(data.postalAddress) {
      this.postalAddress = new PostalAddress(data.postalAddress);
    }
  }

  getApiData() {
    const clone = JSON.parse(JSON.stringify(this));
    delete clone.postalAddress
    clone.contactPhone1 = '+34' + clone.contactPhone1;
    return clone
  }

}
