import AuthenticationRepository from "@/api/AuthenticationRepository";
import auth from '@/api/auth'
import {Customer} from "@/models/Customer";
import CustomerRepository from "@/api/CustomerRepository";


const state = {
    token: auth.getToken(),
    customer: auth.getCustomer(),
}

const mutations = {
    setCustomer(state, customer) {
        state.customer = customer
    },
    setToken(state, token) {
        state.token = token
    }
}

const actions = {
    async tokenLogin({commit}, token) {
        auth.token = token;
        commit('setToken', auth.token);

        const customerResponse = await CustomerRepository.get();
        auth.customer = new Customer(customerResponse.data);
        commit('setCustomer', auth.customer);
    },
    async login({commit}, loginFormData) {
        const {license, username, password} = loginFormData
        const response = await AuthenticationRepository.passwordLogin({
            license: license.trim(),
            username: username.trim(),
            password: password
        })
        auth.token = response.data;
        commit('setToken', auth.token);

        const customerResponse = await CustomerRepository.get()
        auth.customer = new Customer(customerResponse.data)
        commit('setCustomer', auth.customer);
    },
    async autoLogin({commit}, token) {
        auth.token = token;
        commit('setToken', auth.token);

        const customerResponse = await CustomerRepository.get();
        auth.customer = new Customer(customerResponse.data);
        commit('setCustomer', auth.customer);
    },
    async updateCustomer({commit}, customer) {
        await CustomerRepository.update(customer.id, customer)
        commit('setCustomer', customer)
    },

    async updateCustomerData({commit}, customer) {
        auth.customer = new Customer(customer)
        commit('setCustomer', auth.customer)
    },

    logout({commit}) {
        return new Promise((resolve) => {
            auth.logout();
            commit('setToken', null);
            commit('setCustomer', null);
            resolve();
        })
    },
}

const getters = {
    customer(state) {
      return state.customer ? new Customer(state.customer) : state.customer
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
