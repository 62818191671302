export class BaseModel{
  mapFields(data, mappableFields){
    mappableFields.forEach(field => {
      if(data[field] !== undefined){
        this[field] = data[field];
      }
    });
  }

  getApiData(){
    return this
  }

}
