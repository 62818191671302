<template>
  <div class="wdgt-container" ref="container">
    <template>
      <span v-if="!customerOrders" class="mb-3">{{ $t('messages.loading') }}</span>
      <span v-if="customerOrders && customerOrders.length === 0" class="mb-3">{{ $t('messages.emptyOrders') }}</span>
      <div class="clear-both"></div>
      <table v-if="customerOrders && customerOrders.length > 0">
        <thead>
          <tr>
            <th class="wdgt-date">{{ $t('words.date') }}</th>
            <th class="wdgt-status">{{ $t('words.status') }}</th>
            <!--th class="wdgt-paymentDate">{{ $t('words.paymentDate') }}</th-->
            <th class="paymentMethod">{{ $t('words.paymentMethod') }}</th>
            <th class="wdgt-total">{{ $t('words.total') }}</th>
            <th class="wdgt-download"></th>
            <th class="wdgt-details"></th>
            <!-- th class="wdgt-cancel"></th-->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in customerOrders" :key="index+'-'+item.orderId">
            <td class="wdgt-date"> {{ item.createdAt | toDate | dateTimeFormat }}</td>
            <td class="wdgt-status">{{ $t('_orderStatus.'+ item.status) }}</td>
            <!--td class="wdgt-paymentDate">{{ item.paymentDate | toDate | dateTimeFormat }}</td-->
            <td class="paymentMethod">{{ $t('_paymentMethod.'+ item.paymentMethod) }}</td>
            <td class="wdgt-total">{{ item.priceWithTaxes | currency }}</td>
            <td class="wdgt-download">
              <a v-if="item.invoice && item.invoice.fileId" style="cursor: pointer;" href="#" :title="$t('words.downloadInvoice')" @click="downloadFile(item.invoice.fileId)">
                <i class="material-icons-round">download</i> {{ $t('words.invoice') }}
              </a>
            </td>
            <td class="wdgt-details">
              <a v-if="item.status === 'refunded'" style="cursor: pointer;" href="#" :title="$t('words.viewOrder')" @click="setPreview(item, true)">
                <i class="material-icons-round">visibility</i> {{ $t('words.order') }}
              </a>
              <a v-else style="cursor: pointer;" href="#" :title="$t('words.viewOrder')" @click="setPreview(item, false)">
                <i class="material-icons-round">visibility</i> {{ $t('words.order') }}
              </a>
            </td>
            <!--td class="wdgt-cancel" v-if="item.status === 'paid'"><a>Cancelar pedido</a></th>
            <th class="wdgt-cancel" v-else></td-->
          </tr>
        </tbody>
      </table>
      <modal v-show="preview.show" hide-footer size="xl" id="modal-order-details" :title="preview.title" @close="preview.show = false">
        <h3 slot="header">{{preview.title}}</h3>
        <div slot="body">
          <order-details v-if="preview.show" :order="preview.order" :ref="'orderDetails_' + preview.order.id" />
        </div>
      </modal>
    </template>
  </div>
</template>
<script>
import OrderRepository from "@/api/OrderRepository";
import {downloadFile} from "@/utils/functions";
import orderDetails from '@/components/orderDetails';
import Modal from '@/components/modal';
export default {
  props: {
    filters: { type:Object, required: false},
    orders: { type:Object, required: false}
  },
  components: {
    orderDetails,
    Modal
  },
  data() {
    return {
      customerOrders : null,
      preview:{
        show:false,
        order:null,
        title:''
      },
    }
  },
  computed: {},
  created() {
    this.getOrders();
  },

  methods: {
    downloadFile,
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    },
    async getOrders() {
      const response = await OrderRepository.customerOrders(this.filters,this.orders);
      this.customerOrders = response.data.items;
    },
    setPreview(order, refund=false){
      this.preview.order = order;
      this.preview.show = true;
      this.preview.refund = refund;
      this.preview.title = this.preview.refund ? this.$t('actions.refundDetails') : this.$t('words.orderDetails');
    },

  }
}
</script>

<style scoped>

table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #dedede;
  border-right: none;
  border-bottom: none;
  border-radius: 5px;
  color: #464958;
  margin: auto auto 15px;
  font-size: 16px;
  overflow-x: auto;
  white-space: nowrap;
}

thead {
  background-color: #fafafa;
}

th,
td {
  border: 1px solid #dedede;
  border-left: none;
  border-top: none;
  padding: 5px;
  vertical-align: middle;
}
i.material-icons-round {
  font-size: 16px;
  vertical-align: middle;
}
</style>
