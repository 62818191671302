import { render, staticRenderFns } from "./customerProfile.vue?vue&type=template&id=5496a0e8&scoped=true&"
import script from "./customerProfile.vue?vue&type=script&lang=js&"
export * from "./customerProfile.vue?vue&type=script&lang=js&"
import style0 from "./customerProfile.vue?vue&type=style&index=0&id=5496a0e8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5496a0e8",
  null
  
)

export default component.exports