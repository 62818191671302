var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.customer)?_c('div',{ref:"container",staticClass:"wdgt-container",attrs:{"id":"profile-container"}},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",on:{"submit":function($event){$event.preventDefault();$event.stopPropagation();return handleSubmit(_vm.update)}}},[_c('div',{staticClass:"wdgt-form"},[_c('div',{staticClass:"form-field"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wdgt-name"}},[_vm._v(_vm._s(_vm.$t('words.name'))+"*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.contactName),expression:"customer.contactName"}],attrs:{"id":"wdgt-name","type":"text","placeholder":_vm.$t('words.name')},domProps:{"value":(_vm.customer.contactName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customer, "contactName", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-field"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wdgt-surname"}},[_vm._v(_vm._s(_vm.$t('words.surname'))+"*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.contactSurname),expression:"customer.contactSurname"}],attrs:{"id":"wdgt-surname","type":"text","placeholder":_vm.$t('words.surname')},domProps:{"value":(_vm.customer.contactSurname)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customer, "contactSurname", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-field"},[_c('validation-provider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wdgt-email"}},[_vm._v(_vm._s(_vm.$t('words.email'))+"*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.contactEmail),expression:"customer.contactEmail"}],attrs:{"id":"wdgt-email","type":"email","placeholder":_vm.$t('words.email')},domProps:{"value":(_vm.customer.contactEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customer, "contactEmail", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-field"},[_c('validation-provider',{attrs:{"rules":"required|phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wdgt-phone"}},[_vm._v(_vm._s(_vm.$t('words.phone'))+"*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.contactPhone1),expression:"customer.contactPhone1"}],attrs:{"id":"wdgt-phone","type":"text","placeholder":_vm.$t('words.phone')},domProps:{"value":(_vm.customer.contactPhone1)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customer, "contactPhone1", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-field"},[_c('validation-provider',{attrs:{"rules":"validateCIFNIFNIE"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"wdgt-vat"}},[_vm._v(_vm._s(_vm.$t('words.vat')))]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.vat),expression:"customer.vat"}],attrs:{"id":"wdgt-vat","type":"text","placeholder":_vm.$t('words.vat')},domProps:{"value":(_vm.customer.vat)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customer, "vat", $event.target.value)}}}),_c('span',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-field"},[_c('label',{attrs:{"for":"wdgt-company"}},[_vm._v(_vm._s(_vm.$t('words.company'))+" ("+_vm._s(_vm.$t('words.optional'))+")")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.customer.companyName),expression:"customer.companyName"}],attrs:{"id":"wdgt-company","type":"text","placeholder":_vm.$t('words.company')},domProps:{"value":(_vm.customer.companyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.customer, "companyName", $event.target.value)}}})]),_c('div',{staticClass:"form-field checkbox"},[_c('input',{attrs:{"id":"wdgt-terms","type":"checkbox","required":""}}),_c('label',{attrs:{"for":"wdgt-terms"}},[_vm._v(" "+_vm._s(_vm.$t('words.beforePrivacyPolicy'))+" "),_c('a',{attrs:{"href":"/politica-de-privacitat/","title":_vm.$t('words.privacyPolicy'),"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('words.privacyPolicy')))]),_vm._v("* ")])])]),_c('div',{staticClass:"checkout-buttons"},[_c('button',{staticClass:"updateButton green d-flex ml-auto",attrs:{"type":"submit","disabled":_vm.disableSaveButton}},[_vm._v(" "+_vm._s(_vm.$t('actions.send'))+" ")])])])]}}],null,false,556728912)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }