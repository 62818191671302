import Base from './loginBase'
import AuthenticationRepository from "@/api/AuthenticationRepository";

export default class FacebookLogin extends Base {
  static SCRIPT_ID = 'fb-sdk'
  static SCRIPT_URL = 'https://connect.facebook.net/en_US/sdk.js'

  constructor (app) {
    super(app)
    this.initialize()
  }

  initialize () {
    const callback = function () {
      window.FB.init({
        appId: process.env.VUE_APP_FACEBOOK_APP_ID,
        xfbml: false,
        cookie: true,
        status: true,
        version: 'v8.0'
      });
    }
    this.loadScript(FacebookLogin.SCRIPT_ID, FacebookLogin.SCRIPT_URL, callback);
  }

  destroy () {
    this.deleteScript(FacebookLogin.SCRIPT_ID)
    delete window.FB
  }

  login () {
    window.FB.login(this.onResponse.bind(this), { scope: 'public_profile, email' })
  }

  getToken () {
    return new Promise((resolve) => {
      window.FB.getLoginStatus((response) => {
        if (response.status === 'connected') {
          resolve(response.authResponse.accessToken)
        }
        resolve(null)
      })
    })
  }

  requirePermissions () {
    const promiseFunction = function (resolve, reject) {
      const callback = function (response) {
        if (response.status !== 'connected') {
          reject(response)
          return
        }
        resolve(response)
      }
      window.FB.login(callback, { scope: 'public_profile, email, pages_show_list, pages_manage_posts' })
    }
    return new Promise(promiseFunction)
  }

  logout () {
    try {
      window.FB.logout()
    } catch (err) {
      console.warn(err)
    }
    super.logout()
  }

  async onResponse (response) {
    if (response.status !== 'connected') {
      return
    }
    await this.onLogin(response)
  }

  async onLogin (response) {
    try {
      const apiResponse = await AuthenticationRepository.facebookLogin({
        license: this.getLicense(),
        token: response.authResponse.accessToken
      });
      await this.app.$store.dispatch('session/tokenLogin', apiResponse.data);
    } catch (err) {
      this.onFailure(err)
      return false;
    }
    window.dispatchEvent(new CustomEvent('bambuyo:close-all')); // close all modals
    if (this.app.$store.getters['basket/orderDetails'].length > 0) {
      window.dispatchEvent(new CustomEvent('bambuyo:basket')); // open basket modal if there are products
    }
    return true;
  }

  getLicense() {
    return window._pg?.license || process.env.VUE_APP_LICENSE;
  }

}
