<template>
  <div id="_pg-app">
    <VisitarBodegas v-if="License.isVisitarBodegas()" />
    <SafareigTermal v-else-if="License.isSafareigTermal()" />
    <CastellDelRemei v-else-if="License.isCastellDelRemei()" />
    <MainLayout v-else />
  </div>
</template>

<script>
import MainLayout from './layouts/main.vue'
import VisitarBodegas from "@/layouts/visitarBodegas";
import SafareigTermal from "@/layouts/safareigTermal.vue";
import CastellDelRemei from "@/layouts/castellDelRemei.vue";
import TaxRepository from "@/api/TaxRepository";
import store from "@/store";
import License from "@/utils/License";

export default {
  name: 'App',
  computed: {
    License() {
      return License
    }
  },
  components: {
    CastellDelRemei,
    SafareigTermal,
    VisitarBodegas,
    MainLayout
  },
  async created() {
    const taxesResponse = await TaxRepository.find();
    const taxes = {};
    taxesResponse.data.items.forEach(tax => taxes[tax.id] = tax);
    store.commit('basket/setTaxes', taxes);
  },
  methods: {
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/icon?family=Material+Icons+Round&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.5.0/css/all.min.css');

div#_pg-app{
  padding: 15px;
}

div#_pg-app *{
  box-sizing: border-box;
}
</style>
