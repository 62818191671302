import { Order } from '@/models/Order';
import OrderRepository from '@/api/OrderRepository';
import DOM from '@/utils/dom';
import LocalStorage from '@/utils/LocalStorage'
import store from '@/store'
import SweetAlert from "sweetalert2";
import {i18n} from '@/lang';

function getInitialState() {
  LocalStorage.setAppKey(window._pg?.license || process.env.VUE_APP_LICENSE)
  const orderData = LocalStorage.get('order', {});
  orderData.paymentMethod = 'online'
  return {
    order: new Order([], orderData)
  }
}

const mutations = {
  setTaxes(state, taxes){
    state.order.taxes = taxes
  },
  addProduct(state, product){
    state.order.addProduct(product)
    LocalStorage.set('order', state.order)
  },
  removeProduct(state, index){
    state.order.removeLine(index)
    LocalStorage.set('order', state.order)
  },
  clear(state){
    state.order.empty()
    LocalStorage.remove('order')
  },
  setQuantity(state, params){
    state.order.setQuantity(params.index, params.quantity)
    LocalStorage.set('order', state.order)
  },
  setOrderId(state, orderId){
    state.order.id = orderId
    LocalStorage.set('order', state.order)
  }
}

const actions = {
  addProduct({commit}, product){
    commit('addProduct', product);
  },
  removeProduct({commit}, index){
    commit('removeProduct', index);
  },
  clear({commit}){
    commit('clear');
  },
  setQuantity({commit}, params){
    commit('setQuantity', params);
  },
  async pay(app, orderId){
    let response = await OrderRepository.getPaymentData(orderId);
    const paymentData = response.data
    if(paymentData.type ==='form') {
      let form = DOM.createElement('form', {action: paymentData.url, method: 'POST'});
      for (let key in paymentData.fields) {
        let field = DOM.createElement('input', {type: 'hidden', name: key, value: paymentData.fields[key]});
        form.appendChild(field);
      }
      document.body.appendChild(form);
      form.submit();
      setTimeout(form.remove, 200)
    } else {
      window.location.href = paymentData.url;
    }
  },
  async checkout({state, dispatch, commit}){

    if (state.order.id) { // ya existe la orden
      await OrderRepository.findById(state.order.id).then((response) => {
        if (response.data.status === 'paid') { // ya está pagada, borramos la cesta
          commit('clear');
        } else {
          // no está pagada, eliminamos la antigua orden y creamos una nueva por si se han modificado los productos
          /*OrderRepository.delete(state.order.id).finally(() => {
            commit('setOrderId', null);
          });*/
        }
      }).catch((error) => {
        if (error.response.status === 404) { // no existe ese pedido, seguramente porque caducó por tardar demiasiado en pagar
          commit('setOrderId', null); // eliminamos la orden de la cesta
        }
      });
    }

    // no existe la orden, hay que crearla y pagarla
    let order = new Order([], state.order);
    order.setCustomerId(store.getters["session/customer"].id);
    await OrderRepository.create(order)
    .then((response) => {
      commit('setOrderId', response.data.id);
      dispatch('pay', response.data.id);
    })
    .catch((error) => {
      if (error.response.data.code === 1000008) {

        const startsAt = new Date(error.response.data.extra.startsAt);
        const productId = error.response.data.extra.productId;
        const productName = error.response.data.extra.productName;

        SweetAlert.fire({
          title: i18n.t('messages.noBookingPossible'),
          html:  i18n.t('messages.theBookingOf') + productName + i18n.t('messages.theDay') + startsAt.toLocaleString()  + i18n.t('messages.noFurtherBookingsAccepted'),
          icon: 'info'
        });

        // eliminamos la reserva conflictiva de la cesta
        for (const index in state.order.orderDetails) {
          if (state.order.orderDetails[index].productId === productId) {
            dispatch('removeProduct', index);
          }
        }

      } else {
        SweetAlert.fire({
          title: i18n.t('messages.errorCreatingOrder'),
          html:  i18n.t('messages.thereSeemsToHaveAProblemWhenPlacingOrder'),
          icon: 'error'
        });
      }
    });
  }
}

const getters = {
  order(state){
    return state.order
  },
  orderDetails(state){
    return state.order.orderDetails
  },
  products(state){
    return state.order.orderDetails.map(od => od.product)
  },
  bookings:state => bookingCalendarId => {
    return state.order.orderDetails
      .filter(od => !od.id) // que sean lineas de detalle nuevas no "editando"
      .filter(od => !!od.booking) // que tengan configurada una reserva (producto reservable)
      .filter(od => od.booking.bookingCalendarId === bookingCalendarId); // que sean del calendario pedido
  }
}

export default {
  namespaced: true,
  state:getInitialState(),
  mutations,
  actions,
  getters
}
