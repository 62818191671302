import {BaseModel} from './BaseModel';

export class ImageFile extends BaseModel {
  id = '';
  licenseId = '';
  checksum = '';
  mimetype = '';
  size = '';
  status = '';

  constructor(data){
    super(data);
    if(!data) {
      return;
    }
    let mappableFields = ['id', 'licenseId', 'checksum', 'mimetype', 'size', 'status'];
    this.mapFields(data, mappableFields);
  }
}