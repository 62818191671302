<template>
  <li v-if="!customer">
    <a @click="showLogin()" class="sub-menu-item link">Login</a>
  </li>
  <li v-else class="has-submenu parent-parent-menu-item">
    <a class="sub-menu-item">{{ customer.contactName }}</a>
    <span class="menu-arrow"></span>
    <ul class="submenu open">
      <li><a class="link" onclick="window.dispatchEvent(new CustomEvent('bambuyo:profile'));">{{ $t('messages.myProfile') }}</a></li>
      <li><a class="link" onclick="window.dispatchEvent(new CustomEvent('bambuyo:my-orders'));">{{ $t('messages.myOrders') }}</a></li>
      <li><a class="link" onclick="window.dispatchEvent(new CustomEvent('bambuyo:recovery-pass'));">{{ $t('messages.changePassword') }}</a></li>
      <li><a class="link" @click="logout()">{{ $t('messages.logOut') }}</a></li>
    </ul>
  </li>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  components: { },
  props: {
    // style: { type: String, required: false, default: null }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('session', ['customer'])
  },
  methods: {
    ...mapActions('session', ['logout']),
    showLogin() {
      window.dispatchEvent(new CustomEvent('bambuyo:login'));
    }
  }
}
</script>

<style scoped>
</style>
