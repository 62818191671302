import {BaseModel} from './BaseModel';
import {Product, OrderDetail, Booking} from './OrderDetail';
import { toLocalTimestamp } from "@/utils/date"

export class Order extends BaseModel {
  id;
  licenseId;
  createdAt;
  paymentMethod = 'cash';
  generateInvoice = true;
  customerId;
  orderDetails = [];
  deleteOrderDetails = [];
  status = 'draft';

  constructor(taxesData, data){
    super();
    this.taxes = taxesData
    if(!data){
      return;
    }
    let mappableFields = ['id', 'licenseId', 'createdAt', 'paymentMethod', 'status', 'generateInvoice'];
    this.mapFields(data, mappableFields);
    this.mapDetails(data);
  }

  setId(orderId){
    this.id = orderId;
  }

  setCustomerId(customerId){
    this.customerId = customerId;
  }

  get taxPrice(){
    return this.priceWithTaxes - this.priceWithoutTaxes;
  }

  get priceWithTaxes(){
    return this.orderDetails
      .map(od => od.totalWithTaxes)
      .reduce((a,b) => a+b, 0);
  }

  get priceWithoutTaxes(){
    return this.orderDetails
      .map(od => od.totalWithoutTaxes)
      .reduce((a,b) => a+b, 0);
  }

  get totalItems(){
    return this.orderDetails
      .map(od => +od.quantity)
      .reduce((a,b) => a+b, 0);
  }

  mapDetails(data){
    if(data.orderDetails){
      this.orderDetails = data.orderDetails.map(od => new OrderDetail(od));
    }
  }

  getTaxDisclosure(showDiscounts = true){
    let taxes = {};
    // START SAFAREGI TERMAL : FORCE 50% DISCOUNT IN ONLINE PRODUCTS
    let idsProductWithDiscount = [
      '73ffe65e-2bbb-491c-8ecc-67f659c73175' //Entrada general
    ];
    const discountStartDate = new Date('2023-06-01 00:00:00');
    const discountEndDate = new Date('2023-09-30 23:59:59');
    // END SAFAREGI TERMAL : FORCE 50% DISCOUNT IN ONLINE PRODUCTS
    this.orderDetails.forEach(d => {
      const taxId = d.product.taxId
      if (this.taxes[taxId] !== undefined) {
        if(!taxes[taxId]){
          taxes[taxId] = {
            percent:this.taxes[taxId].rate,
            base:0,
            total:0
          };
        }
        const hoursDiscount = [14, 21];
        if( showDiscounts &&
            idsProductWithDiscount.includes(d.productId) &&
            hoursDiscount.includes(new Date(d.booking.startsAt).getHours()) &&
            toLocalTimestamp(new Date(d.booking.startsAt)) >= toLocalTimestamp(discountStartDate) &&
            toLocalTimestamp(new Date(d.booking.startsAt)) <= toLocalTimestamp(discountEndDate)
        ){
          taxes[taxId].base += ((d.priceWithoutTaxes - 2.0661) * d.quantity);
        }else{
          taxes[taxId].base += d.priceWithoutTaxes * d.quantity;
        }
        taxes[taxId].total = taxes[taxId].base * this.taxes[taxId].rate / 100;
      }
    });
    return Object.values(taxes);
  }

  getTotal(){
    let total = 0
    const taxes = this.getTaxDisclosure()
    Object.keys(taxes).forEach(key => {
      total += taxes[key].base + taxes[key].total
    })
    return total
  }

  getNewBookings(bookingCalendarId){
    return this.orderDetails
      .filter(od => !od.id)
      .filter(od => !!od.booking)
      .filter(od => od.booking.bookingCalendarId === bookingCalendarId)
      .map(od => {return {startsAt: od.booking.startsAt, quantity: od.quantity}});
  }

  addProduct (productData) {
    const product = new Product(productData);
    const foundOrderDetail = this.orderDetails.find(od => od.productId === product.id)


    if (foundOrderDetail) {
      // si encontramos el producto ya en la cesta solo aumentamos la cantidad
      if (foundOrderDetail.product.type !== 'bookable' || productData.booking.startsAt === foundOrderDetail.booking.startsAt) {
        foundOrderDetail.quantity++
        return
      }
    }

    // sino es que es un nuevo producto en la cesta hay que crear una nueva linea de detalle en la cesta
    const orderDetail = new OrderDetail();
    orderDetail.setProduct(product);
    if (productData.booking) {
      productData.booking.bookingCalendarId = product.bookingCalendarId
      orderDetail.setBooking(new Booking(productData.booking));
    }
    this.orderDetails.push(orderDetail);
  }

  setQuantity (index, quantity) {
    if (!this.orderDetails[index] || !quantity) {
      return false;
    }
    this.orderDetails[index].quantity = quantity;
  }

  empty () {
    this.id = null;
    this.orderDetails = []
  }

  removeLine(index){
    if(!this.orderDetails[index])
      return
    if(this.orderDetails[index].id)
      this.deleteOrderDetails.push(this.orderDetails[index].id)
    this.orderDetails.splice(index, 1);
  }

}
