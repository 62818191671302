import request from '@/utils/request'

const baseApi = '/private/authentication'

export default class AuthenticationRepository {

  static passwordLogin(data) {
    return request({
      url: baseApi + '/customer-password-login',
      method: 'post',
      data: data
    })
  }

  static googleLogin(data) {
    return request({
      url: baseApi + '/customer-google-login',
      method: 'post',
      data: data
    })
  }

  static facebookLogin(data) {
    return request({
      url: baseApi + '/customer-facebook-login',
      method: 'post',
      data: data
    })
  }

}
