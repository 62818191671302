export default class LocalStorage {
  static appKey = 'default';

  static get (key, deflt) {
    const data = localStorage.getItem(this.getKey(key))
    if (data) {
      try {
        return JSON.parse(data)
      } catch (e) {
        console.warn('LocalStorage: Cannot decode key ' + this.getKey(key))
      }
    }
    return deflt
  }
  static set (key, data) {
    localStorage.setItem(this.getKey(key), JSON.stringify(data))
  }
  static remove (key) {
    localStorage.removeItem(this.getKey(key))
  }

  static setAppKey (key) {
    if (!key) {
      throw new Error('No key specified')
    }
    LocalStorage.appKey = key;
  }

  static getKey(key) {
    return key + '-' + LocalStorage.appKey;
  }
}
