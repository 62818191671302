<template>
  <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-basket">
    <a v-if="order.orderDetails.length" href="#" @click.prevent="showBasket()"><i class="material-icons-round" style="vertical-align: -6px;">shopping_basket</i><span class="text">{{ $t('words.basket') }}</span><span class="badge">{{ countBasketItems() }}</span></a>
    <!--a v-else href="#" @click.prevent="showBasket()"><i class="material-icons-round" style="vertical-align: -6px;">shopping_basket</i> <span class="text">Cistella</span></a-->
  </li>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  components: { },
  props: {
    // style: { type: String, required: false, default: null }
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters('basket', ['order'])
  },
  methods: {
    showBasket() {
      window.dispatchEvent(new CustomEvent('bambuyo:basket'));
    },
    getLicense() {
      return window._pg?.license || process.env.VUE_APP_LICENSE;
    },
    countBasketItems() {
      let num = 0
      if (this.order.orderDetails.length) {
        this.order.orderDetails.forEach(item => {
          num += item.quantity
        })
      }
      return num;
    }
  }
}
</script>

<style scoped>
  .badge {
    background-color: #fa3e3e;
    border-radius: 2rem;
    color: white;
    padding: 0px 7px;
    font-size: 10px;
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .menu-item-basket a {
    padding: 0px 8px !important;
  }
</style>
