import axios from 'axios'
import auth from "@/api/auth";
import store from '@/store'

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 20000
})

service.interceptors.request.use(
  config => {
    config.headers['license'] = window._pg?.license || process.env.VUE_APP_LICENSE
    if (document.documentElement.lang) {
        config.headers['Accept-Language'] = document.documentElement.lang.split('-')[0]
    }
    if (auth.isAuthenticated()) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = 'Bearer ' + auth.getToken();
    }
    return config
  },
  error => {
    console.error(error)
    return Promise.reject(error)
  }
)

service.interceptors.response.use(
  response => {
    return response.data
  },
  error => {
    if (error.response !== undefined) {
      if (error.response.status === 401) {
          store.dispatch('session/logout').then(() => {
              location.reload()
          })
      }
    }
    // console.error('request.js', error);
    return Promise.reject(error)
  }
)

export default service
