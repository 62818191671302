import request from '@/utils/request'
import { RequestParams } from '@/api/utils/requestUtils'

const publicApi = '/public/products';

export default class ProductRepository {
  static find(filter, sort, paging) {
    const requestParams = new RequestParams(filter, sort, paging)
    return request({
      url: requestParams.buildUrl(publicApi),
      method: 'get'
    })
  }
}
