<template>
  <div v-if="product">
    <h2 class="text-center">
      {{ $t('messages.selectBookingdate') }}
    </h2>
    <p class="text-center">
      <b>{{ product.name }}</b>
    </p>
    <div id="_pg-calendar">
      <v-calendar
          ref="calendar"
          :available-dates="availableDates"
          :attributes="attributes"
          is-inline
          is-expanded
          @dayclick="onDayClick"
      />
      <div class="legend">
        <div class="legend-item">
          <div class="legend-color" style="background-color: #f6b4bf"></div>
          <div class="legend-text">{{ $t('messages.soldOut') }}</div>
        </div>
        <div class="legend-item">
          <div class="legend-color" style="background-color: #f8df81"></div>
          <div class="legend-text">{{ $t('messages.lastUnits') }}</div>
        </div>
        <div class="legend-item">
          <div class="legend-color" style="background-color: #9bd0b7"></div>
          <div class="legend-text">{{ $t('messages.available') }}</div>
        </div>
      </div>
      <div v-if="availableHours" class="eventsContainer">
        <div class="header">{{ date | dateFormat }}</div>
        <div class="events">
          <div
              v-for="event in availableHours.events"
              :key="event.startsAt"
              class="event-wrapper"
          >
            <div :class="'event ' + event.status" @click="selectEvent(event)">
              <span class="eventTimeRange">{{ event.startHour }} - {{ event.endHour }}</span><br>
              <span v-if="event.status === 'exceptionally_closed'">{{ $t('messages.exceptionallyClosed') }}</span>
              <span v-else>{{ $t('messages.xOfYAvailable', {x: event.bookingsAvailable, y: event.bookingsCapacity}) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button @click="cancel()" type="button" class="secondary">{{ $t('actions.cancel') }}</button>
    </div>
  </div>
</template>

<script>
import BookingCalendarRepository from '@/api/BookingCalendarRepository'

export default {
  props: {
    product: {required: true}
  },
  data() {
    return {
      date: null,
      config: {
        minimumMinutesInAdvanceForBooking: 0,
        maximumDaysOfBookingForecast: 4
      },
      events: null,
      datesMap: null,
      availableDates: [false],
      availableHours: null
    }
  },
  computed: {
    minDate() {
      let date = new Date();
      date.setMinutes(date.getMinutes() + this.config.minimumMinutesInAdvanceForBooking);
      return date;
    },
    maxDate() {
      let date = new Date();
      date.setDate(date.getDate() + this.config.maximumDaysOfBookingForecast);
      return date;
    },
    attributes() {
      if (!this.datesMap) {
        return [];
      }

      let greenDates = [];
      let orangeDates = [];
      let redDates = [];

      Object.keys(this.datesMap).forEach(key => {
        if (this.datesMap[key].reservable === 0 && this.datesMap[key].completed > 0) {
          redDates.push(key);
        } else if (this.datesMap[key].reservable > 0 && this.datesMap[key].reservable <= this.datesMap[key].completed) {
          orangeDates.push(key);
        } else if (this.datesMap[key].reservable > 0 && this.datesMap[key].reservable > this.datesMap[key].completed) {
          greenDates.push(key);
        }
      });

      return [
        {
          dates: redDates,
          highlight: {
            color: 'red', // #f6b4bf
            fillMode: 'light',
          }
        },
        {
          dates: orangeDates,
          highlight: {
            color: 'orange', // #f8df81
            fillMode: 'light',
          }
        },
        {
          dates: greenDates,
          highlight: {
            color: 'green', // #9bd0b7
            fillMode: 'light',
          }
        }
      ];
    }
  },
  mounted() {
    this.reset();
  },

  async created() {
    let response = await BookingCalendarRepository.findById(this.product.bookingCalendarId);
    this.config.minimumMinutesInAdvanceForBooking = response.data.minimumMinutesInAdvanceForBooking;
    this.config.maximumDaysOfBookingForecast = response.data.maximumDaysOfBookingForecast;
    await this.getSlots();
  },
  methods: {
    cancel() {
      this.reset();
      this.$emit('cancel');
    },
    reset(){
      this.date = null;
      this.events = null;
      this.datesMap = null;
      this.availableDates = [false];
      this.availableHours = null;
    },
    async getSlots(){
      this.reset();
      let start = this.minDate;
      let end = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate()).toISOString();
      let response = await BookingCalendarRepository.findCalendarSlots(this.product.id, start, end);
      this.events = response.data;
      this.setDatesMap();
      this.setAvailableDates();
      this.goToAvailableDates();
    },
    formatDateId(date){
      let year = date.getFullYear() + '';
      let month = (date.getMonth()+1) + '';
      month = (month.length === 2) ? month : '0' + month;
      let day = date.getDate() + '';
      day = (day.length === 2) ? day : '0'+day;

      return year + '-' + month + '-' + day;
    },
    formatTime(date) {
      let hour = date.getHours() + '';
      hour = (hour.length === 2) ? hour : '0'+hour;
      let minutes = date.getMinutes() + '';
      minutes = (minutes.length === 2) ? minutes : '0' + minutes;

      return hour + ':' + minutes;
    },
    convertTZ(date, tzString) {
      return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));
    },
    mapSlot(slot){
      slot.startDate = this.convertTZ(slot.startsAt, 'Europe/Madrid');
      slot.endDate = this.convertTZ( new Date(Date.parse(slot.endsAt) + 1000) , 'Europe/Madrid');
      slot.date = this.convertTZ(slot.startDate, 'Europe/Madrid');
      //TODO maintain hours and minutes when api gets fixed
      slot.date.setHours(0);
      slot.date.setMinutes(0);
      slot.date.setSeconds(0);
      slot.date.setMilliseconds(0);
      slot.startHour = this.formatTime(slot.startDate);
      slot.endHour = this.formatTime(slot.endDate);
      const slotStartTS = Date.parse(slot.startsAt);
      const slotEndTS = Date.parse(slot.endsAt);
      slot.id = slotStartTS + '-' + slotEndTS;
      slot.dateId = this.formatDateId(slot.date);

      // fill the slot with the basket bookings that periods overlaps
      let bookingOrderDetails = this.$store.getters['basket/bookings'](this.product.bookingCalendarId);
      bookingOrderDetails.forEach(bookingOrderDetail => {
        const bodStartTS = Date.parse(bookingOrderDetail.booking.startsAt);
        const bodEndTS = Date.parse(bookingOrderDetail.booking.endsAt);
        if (this.periodsOverlaps(bodStartTS, bodEndTS, slotStartTS, slotEndTS)) {
          slot.bookingsOccupied++;
          slot.bookingsAvailable--;
        }
      });

      slot.status = 'reservable'
      if (slot.bookingsOccupied >= slot.bookingsAvailable) {
        slot.status = 'almost-complete'
      }
      if (!slot.bookingsAvailable) {
        slot.status = 'completed'
      }

      return slot;
    },
    periodsOverlaps(
        startTs1,
        endTs1,
        startTs2,
        endTs2
    ) {
      return !((endTs1 < startTs2 || startTs1 > endTs2));
    },
    setDatesMap() {

      if (!this.events) {
        this.datesMap = null;
      }

      let datesMap = {};

      this.events.map(ev => this.mapSlot(ev))
          .forEach(ev => {
            if (!datesMap[ev.dateId]) {
              datesMap[ev.dateId] = {
                available: false,
                exceptionally_closed: 0,
                completed: 0,
                reservable: 0,
                events: []
              };
            }
            datesMap[ev.dateId].events.push(ev);
          });

      for (const key in datesMap) {

        for (const ev of datesMap[key].events) {
          if (ev.status === 'completed') {
            datesMap[key].completed++
          } else if (ev.status === 'reservable') {
            datesMap[key].reservable++
          }
        }

        datesMap[key].available = !(datesMap[key].reservable === 0 && datesMap[key].completed === 0);

      }

      this.datesMap = datesMap;
    },
    setAvailableDates() {
      if (!this.datesMap) {
        return [];
      }
      this.availableDates = Object.keys(this.datesMap).filter(key => {
        return this.datesMap[key].available
      });
    },
    goToAvailableDates() {
      if (!this.availableDates || !this.availableDates.length) {
        return;
      }
      let firstAvailableDate = this.availableDates[0];
      if(this.$refs.calendar){
        this.$refs.calendar.move(firstAvailableDate);
      }
    },
    onDayClick(day) {
      this.date = day.date
      this.availableHours = null;
      if (this.datesMap && this.datesMap[day.id] && this.datesMap[day.id].available) {
        let availableHours = this.datesMap[day.id];
        // sort events by startsAt
        availableHours.events.sort((a, b) => {
          return new Date(a.startsAt) - new Date(b.startsAt)
        });
        this.availableHours = availableHours;
      }
    },
    selectEvent(event) {
      if (event.bookingsAvailable) {
        this.reset();
        this.$emit('bookingSlotSelected', event);
      }
    }
  }
}
</script>

<style scoped>
#_pg-calendar {
  z-index: 99999999;
  position: relative;
  margin: 0 auto 20px;
  width: 98%;
  max-width: 600px;
}

#_pg-calendar .eventsContainer {
  border: 1px solid #dedede;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #f7f7f7;
  padding: 15px;
}

#_pg-calendar .eventsContainer .header {
  text-align: center;
  font-weight: bold;
  padding-bottom: 5px;
  color: #464958;
}

#_pg-calendar .events {
  display: flex;
  flex-wrap: wrap;
}

#_pg-calendar .event-wrapper {
  width: 33.333333%;
  padding: 5px 10px;
}

#_pg-calendar .event {
  color: #464958;
  padding: 15px 10px;
  border-radius: 5px;
  text-align: center;
}

#_pg-calendar .reservable {
  background-color: #c8facc;
  cursor: pointer;
}

#_pg-calendar .almost-complete {
  background-color: #f9d79e;
  cursor: pointer;
}

#_pg-calendar .completed {
  background-color: #f49a9a;
  cursor: not-allowed;
}

@media (max-width: 767px) {
  #_pg-calendar .event-wrapper {
    width: 50%;
  }
}

@media (max-width: 374px) {
  #_pg-calendar .event-wrapper {
    width: 100%;
  }
}

button {
  border: none;
  border-radius: 8px;
  color: #fff;
  padding: 6px 12px;
  background: #c91919;
}

button.secondary {
  background: #acacac;
  color: #fff;
  padding: 0 25px;
  line-height: 40px;
  font-size: 14px;
  border-radius: 3px;
}

.eventTimeRange {
  font-weight: bold;
}
h2.text-center{
  font-family: inherit;
  font-size: 35px;
  font-weight: 400;
  color: #a6192e;
}
p.text-center{
  font-family: inherit;
  font-size: 25px;
  color: #464958;
}

.legend {
  margin-top: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.legend-color {
  width: 20px;
  height: 20px;
  border-radius: 10px;
  margin-right: 5px;
}
</style>
