import request from '@/utils/request'

const publicApi = '/public/gift-vouchers';

export default class OrderRepository {

  static checkVoucher(voucherCode) {
    voucherCode = voucherCode.replace(/[^a-zA-Z0-9]/g,'')
    return request(publicApi + '/' + voucherCode)
  }

  static bookVoucher(voucherCode, startsAt, notes) {
    voucherCode = voucherCode.replace(/[^a-zA-Z0-9]/g,'')
    const data = {startsAt, notes}
    return request({
      url: publicApi + '/' + voucherCode + '/booking',
      method: 'post',
      data
    })
  }

}
