import request from '@/utils/request'
import requestRaw from '@/utils/requestRaw'
import {toQueryString} from "@/utils/functions";

const publicApi = '/public/files'
const baseApi = '/private/files'

export default class FileRepository {
  static resize(id, width = null, height = null) {
    let queryString = toQueryString(
        {
          width,
          height
        },
        true
    );
    return request({
      url: publicApi + '/resize/' + id + queryString,
      method: 'get',
      responseType: 'arraybuffer'
    })
  }
  static download(id) {
    return requestRaw({
      url: baseApi + '/download/' + id,
      method: 'get',
      responseType: 'arraybuffer'
    })
  }
}
